import React from 'react';
import './DDTFaq.css';
import { TEXTS } from '../../texts';
import FAQAccordion from '../FAQAccordion/FAQAccordion';



const DDTFaq = () => {

    const accordions = [
        {
            question: TEXTS.DDTFaqQuestion_1,
            text: TEXTS.DDTFaqText_1,
        },
        {
            question: TEXTS.DDTFaqQuestion_2,
            text: TEXTS.DDTFaqText_2,
        },
        {
            question: TEXTS.DDTFaqQuestion_3,
            text: TEXTS.DDTFaqText_3,
        }
    ]

    return (
        <div className="ddt-faq">
            <div className="ddt-faq-wrapper">

                <h2 className="ddt-faq-title">{TEXTS.FAQ}</h2>

                {accordions.map((accordion, index) => (
                    <FAQAccordion
                        key={index}
                        question={accordion.question}
                        text={accordion.text}
                    />
                ))}

            </div>
        </div>
    )
}

export default DDTFaq;