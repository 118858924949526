import CompanyPresentation from '../components/CompanyPresentation/CompanyPresentation';
import CompanyValue from '../components/CompanyValue/CompanyValue';
import CompanyProcess from '../components/CompanyProcess/CompanyProcess';
import CompanyTeam from '../components/CompanyTeam/CompanyTeam';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { withCookiesCheker } from '../HOCs/withCookiesCheker';
import { useScrollToId } from '../hooks/useScrollToId';



const Company = () => {

  const scrollToId = useScrollToId();

  return (
    <>
      <Header />
      <CompanyPresentation />
      <CompanyValue />
      <CompanyProcess />
      <CompanyTeam />
      <Footer />
    </>
  )
}

export default withCookiesCheker(Company);