import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import OutsourceText from "../components/OutsourceText/OutsourceText";
import OutsourceWork from "../components/OutsourceWork/OutsourceWork";
import OutsourcePros from "../components/OutsourcePros/OutsourcePros";
import OutsourceSuited from "../components/OutsourceSuited/OutsourceSuited";
import OutsourceProcess from "../components/OutsourceProcess/OutsourceProcess";
import OutsourceServicess from "../components/OutsourceServicess/OutsourceServicess";
import OutsourceFaq from "../components/OutsourceFaq/OutsourceFaq";
import OutsourceAnother from "../components/OutsourceAnother/OutsourceAnother";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { withCookiesCheker } from "../HOCs/withCookiesCheker";
import { useScrollToId } from "../hooks/useScrollToId";



const ServiceOutsource = () => {

    const location = useLocation();

    useEffect(() => {
        let servicesHeader = document.getElementById('servicesHeader');
        if (location.pathname === '/services/outsource') {
            servicesHeader.style.color = '#28C0E2';
        }
    }, []);

    const scrollToId = useScrollToId();

    return (
        <>
            <Header />
            <OutsourceText />
            <OutsourceWork />
            <OutsourcePros />
            <OutsourceSuited />
            <OutsourceProcess />
            <OutsourceServicess />
            <OutsourceFaq />
            <OutsourceAnother />
            <Footer />
        </>
    )
}

export default withCookiesCheker(ServiceOutsource);