import './CareersAdvantages.css';
import { TEXTS } from '../../texts';
import Carrers1PngImg from '../../img/careers/careers-img1.webp';
import Carrers1PngImg768 from '../../img/careers/careers-img1-768.webp';
import Carrers2PngImg from '../../img/careers/careers-img2.webp';
import Carrers2PngImg768 from '../../img/careers/careers-img2-768.webp';
import Carrers3PngImg from '../../img/careers/careers-img3.webp';



const CareersAdvantages = () => {



  return (
    <div className="careers-advantages">

      <h2 className="careers-advantages-title">{TEXTS.careersAdvantagesTitle}</h2>

      <div className="careers-advantages-wrapper">

        <div className="careers-pros-card linear-hover">
          <div className="linear-hover-line"></div>
          <div className="careers-pros-card-wrapper">
            <h3 className="careers-pros-card_title">{TEXTS.careersAdvantagesCard_1_name}</h3>
            <span className="careers-pros-card_number">{TEXTS.number01}</span>
          </div>
          <p className="careers-pros-card_text">{TEXTS.careersAdvantagesCard_1_text} </p>
        </div>

        <div className="careers-pros-card linear-hover">
          <div className="linear-hover-line"></div>
          <div className="careers-pros-card-wrapper">
            <h3 className="careers-pros-card_title">{TEXTS.careersAdvantagesCard_2_name}</h3>
            <span className="careers-pros-card_number">{TEXTS.number02}</span>
          </div>
          <p className="careers-pros-card_text">{TEXTS.careersAdvantagesCard_2_text}</p>
        </div>

        <div className="careers-pros-card careers-pros-card-none-border careers-pros-card-none">

        </div>

        <div className="careers-pros-card careers-pros-card-none-border careers-pros-card1-position">
          <picture>
            <source media="(max-width: 1919px)" srcSet="" />
            <source media="(max-width: 1365px)" srcSet={Carrers1PngImg768} />
            <source media="(max-width: 767px)" srcSet="" />
            <img className="careers-pros-card-img" src={Carrers1PngImg} alt="" />
          </picture>
        </div>

        <div className="careers-pros-card careers-pros-card-none-border careers-pros-card-none careers-pros-card2-position" >
          <picture>
            <source media="(max-width: 1919px)" srcSet="" />
            <source media="(max-width: 1365px)" srcSet={Carrers2PngImg768} />
            <source media="(max-width: 767px)" srcSet="" />
            <img className="careers-pros-card-img" src={Carrers2PngImg} alt="" />
          </picture>
        </div>

        <div className="careers-pros-card careers-pros-card-none-border careers-pros-card-none">

        </div>

        <div className="careers-pros-card linear-hover">
          <div className="linear-hover-line"></div>
          <div className="careers-pros-card-wrapper">
            <h3 className="careers-pros-card_title">{TEXTS.careersAdvantagesCard_3_name}</h3>
            <span className="careers-pros-card_number">{TEXTS.number03}</span>
          </div>
          <p className="careers-pros-card_text">{TEXTS.careersAdvantagesCard_3_text}</p>
        </div>

        <div className="careers-pros-card linear-hover">
          <div className="linear-hover-line"></div>
          <div className="careers-pros-card-wrapper">
            <h3 className="careers-pros-card_title">{TEXTS.careersAdvantagesCard_4_name}</h3>
            <span className="careers-pros-card_number">{TEXTS.number04}</span>
          </div>
          <p className="careers-pros-card_text">{TEXTS.careersAdvantagesCard_4_text}</p>
        </div>

        <div className="careers-pros-card careers-pros-card-none-border careers-pros-card-none">

        </div>

        <div className="careers-pros-card linear-hover">
          <div className="linear-hover-line"></div>
          <div className="careers-pros-card-wrapper">
            <h3 className="careers-pros-card_title">{TEXTS.careersAdvantagesCard_5_name}</h3>
            <span className="careers-pros-card_number">{TEXTS.number05}</span>
          </div>
          <p className="careers-pros-card_text">{TEXTS.careersAdvantagesCard_5_text}</p>
        </div>

        <div className="careers-pros-card careers-pros-card-none-border careers-pros-card-position careers-pros-card3-position">
          <picture>
            <source media="(max-width: 1919px)" srcSet="" />
            <source media="(max-width: 1365px)" srcSet={Carrers2PngImg768} />
            <source media="(max-width: 767px)" srcSet="" />
            <img className="careers-pros-card-img" srcSet={Carrers3PngImg} alt="" />
          </picture>
        </div>

        <div className="careers-pros-card linear-hover">
          <div className="linear-hover-line"></div>
          <div className="careers-pros-card-wrapper">
            <h3 className="careers-pros-card_title">{TEXTS.careersAdvantagesCard_6_name}</h3>
            <span className="careers-pros-card_number">{TEXTS.number06}</span>
          </div>
          <p className="careers-pros-card_text">{TEXTS.careersAdvantagesCard_6_text}</p>
        </div>

      </div>
    </div>
  )
}

export default CareersAdvantages;