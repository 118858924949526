import React from 'react';
import './Presentation.css';
import FlashlightWithSnakeAnimation from '../FlashlightWithSnakeAnimation/FlashlightWithSnakeAnimation';
import PresentationText from './PresentationText/PresentationText';

import Main1920Light from '../../img/Main1920Light.webp';
import Main1920Dark from '../../img/Main1920Dark.webp';
import Main1366Light from '../../img/Main1366Light.webp';
import Main1366Dark from '../../img/Main1366Dark.webp';



const Presentation = () => {

  return (
    <>

      <div className='presentation1920'>
        <FlashlightWithSnakeAnimation
          height='978px'
          imgFront={Main1920Light}
          imgBack={Main1920Dark}
          imgFrontId='img__front_1920px'
          imgBackId='img__back_1920px'
          href='/cases'
        />
      </div>

      <div className='presentation1366'>
        <FlashlightWithSnakeAnimation
          height='668px'
          imgFront={Main1366Light}
          imgBack={Main1366Dark}
          imgFrontId='img__front_1366px'
          imgBackId='img__back_1366px'
          href='/cases'
        />
      </div>

      <div className='presentationText'>
        <PresentationText />
      </div>

    </>
  )
}

export default Presentation;
