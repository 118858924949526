import './DDTText.css';
import Flashlight from '../Flashlight/Flashlight';
import DDTFlashlightBlackWhite from '../../img/service/ddt/DDTFlashlightBlack&White.webp';
import DDTFlashlightColor from '../../img/service/ddt/DDTFlashlightColor.webp';
import DDTFlashlightMobile from '../../img/service/ddt/DDTFlashlightMobile.webp';
import cursor from '../../img/move_cursor_black.svg';



const DDTText = () => {



    return (
        <>
            <h3 className="ddt-subtitle">Dedicated Development Team</h3>
            <h1 className="ddt-title">Hire DDT<br />for Any Business Goals</h1>

            <div className="ddt-flashlight-wrapper">
                <Flashlight
                    imgFront={DDTFlashlightBlackWhite}
                    imgBack={DDTFlashlightColor}
                    imgMobile={DDTFlashlightMobile}
                    imgFrontId='img__ddt1'
                    imgBackId='img__ddt2'
                    loopSize={170}
                    cursor={cursor}
                    cursorHeight={120}
                    cursorWidth={120}
                    cursorId='cursor__ddt'
                    objectPosition={'50% 50%'}
                    mobileObjectPosition={'50% 50%'}
                    newTab={false}
                />
            </div>

        </>
    )
}

export default DDTText;