import { useEffect } from "react";
import { useLocation } from "react-router-dom";



export const useScrollToId = () => {

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            setTimeout(() => {
                const anchorLink = `${location.hash}`;
                const anchorButton = document.createElement('a');
                anchorButton.href = anchorLink;
                anchorButton.click();
            }, 1300);
        }
    }, [location]);

}