import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import OutstaffText from "../components/OutstaffText/OutstaffText";
import OutstaffProcess from "../components/OutstaffProcess/OutstaffProcess";
import OutstaffValue from "../components/OutstuffValue/OutstaffValue";
import OutstaffSuited from "../components/OutstaffSuited/OutstaffSuited";
import OutstaffComparison from "../components/OutstaffComparison/OutstaffComparison";
import OutstaffFaq from "../components/OutstaffFaq/OutstaffFaq";
import OutstaffAnother from "../components/OutstaffAnother/OutstaffAnother";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { withCookiesCheker } from "../HOCs/withCookiesCheker";
import { useScrollToId } from "../hooks/useScrollToId";



const ServiceOutstaff = () => {

    const location = useLocation();

    useEffect(() => {
        let servicesHeader = document.getElementById('servicesHeader');
        if (location.pathname === '/services/outstaff') {
            servicesHeader.style.color = '#28C0E2';
        }
    }, []);

    const scrollToId = useScrollToId();

    return (
        <>
            <Header />
            <OutstaffText />
            <OutstaffProcess />
            <OutstaffValue />
            <OutstaffSuited />
            <OutstaffComparison />
            <OutstaffFaq />
            <OutstaffAnother />
            <Footer />
        </>
    )
}

export default withCookiesCheker(ServiceOutstaff);