import React, { useState } from 'react';



const CaseCard = ({ portfolioCase }) => {

    const [changeImage, setChangeImage] = useState(false);

    return (
        <div className="portfolio-case-wrapper" >

            <div className="portfolio-case-iner">
                <div className='portfolio-case-mini-wrapper'>
                    <p className="portfolio-case-year">{portfolioCase.year}</p>
                    <h3 className="portfolio-case-title">{portfolioCase.title}</h3>
                </div>
                <div className="portfolio-case-industries">
                    {portfolioCase.industries.map((item, index) => (
                        <p key={index}>{item}</p>
                    ))}
                </div>
            </div>

            <div className="portfolio-case-information" style={{ overflow: 'hidden', position: 'relative', borderRadius: '12px' }} >

                <div onMouseLeave={() => setChangeImage(false)}>
                    <img className="portfolio-case-banner" src={portfolioCase.banner}
                        alt=""
                        style={{
                            transform: changeImage ? 'translateX(0%)' : 'translateX(0)',
                            transition: 'transform 0.8s',
                            objectFit: 'cover'
                        }}
                        onMouseEnter={() => setChangeImage(true)} />

                    <picture style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        left: '50%',
                        top: '50%',
                        transform: changeImage ? 'translate(-50%, -50%)' : 'translate(-200%, -50%)',
                        transition: 'transform 0.8s',
                        objectFit: 'cover'
                    }}
                    >
                        <img className="portfolio-case-banner" src={portfolioCase.bannerUnderCover} alt="" style={{ objectFit: 'cover' }} />
                    </picture>

                    <img className="portfolio-case-banner-mobile" src={portfolioCase.banner} />

                </div>

                <div style={{ zIndex: 10 }}>

                    {portfolioCase.text.map((item, index) => (
                        <p className="portfolio-case-text" key={index}>{item}</p>
                    ))}
                    <p className="portfolio-case-name">{portfolioCase.caseName}</p>
                </div>
            </div>

        </div>
    )
}

export default CaseCard;