import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import vacanciesService from "./vacanciesService";

const initialState = {
    vacancies: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
};

export const getAllVacancies = createAsyncThunk('vacancies/getAll',

    async (user, thunkAPI) => {
        try {
            return await vacanciesService.getAllVacancies()
        } catch (error) {
            const message = error.response.data
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getOneVacancy = createAsyncThunk('vacancies/getOne',

    async (_id, thunkAPI) => {
        try {
            return await vacanciesService.getOneVacancy(_id)
        } catch (error) {
            const message = error.response.data
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createVacancy = createAsyncThunk('vacancies/create',

    async (data, thunkAPI) => {
        try {
            return await vacanciesService.createVacancy(data)
        } catch (error) {
            const message = error.response.data
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const updateVacancy = createAsyncThunk('vacancies/update',

    async (data, thunkAPI) => {
        try {
            await vacanciesService.updateVacancy(data)
        } catch (error) {
            const message = error.response.data
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const removeVacancy = createAsyncThunk('vacancies/remove',

    async (_id, thunkAPI) => {
        try {
            return await vacanciesService.removeVacancy(_id)
        } catch (error) {
            const message = error.response.data
            return thunkAPI.rejectWithValue(message)
        }
    }
)



export const vacanciesSlice = createSlice({
    name: 'vacancies',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllVacancies.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllVacancies.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.vacancies = action.payload
            })
            .addCase(getAllVacancies.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = 'Error getAll vacancies'
                state.vacancies = null
            })

            .addCase(createVacancy.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createVacancy.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.vacancies.push(action.payload)
            })
            .addCase(createVacancy.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = 'Error create vacancy'
                state.vacancies = null
            })

            .addCase(updateVacancy.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateVacancy.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(updateVacancy.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = 'Error create vacancy'
                state.vacancies = null
            })

            .addCase(removeVacancy.pending, (state) => {
                state.isLoading = true
            })
            .addCase(removeVacancy.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.vacancies = state.vacancies.filter(
                    (vacancy) => vacancy._id != action.payload
                )
            })
            .addCase(removeVacancy.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = 'Error delete vacancy'
                state.vacancies = null
            })
    }

})

export const { } = vacanciesSlice.actions;

export const vacanciesReducer = vacanciesSlice.reducer;