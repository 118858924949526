import './Collage1366.css';
import collage from '../../../img/careers/collage-1366.webp';


const Collage1366 = () => {

    function shadowingAnimation(mouse, block) {
        const loop = document.querySelector('.careersCollage__img1366');
        let x = mouse.pageX;
        let y = mouse.pageY;
        let percW = block.offsetWidth / 100;
        let percH = block.offsetHeight / 100;
        let resW = x - block.offsetLeft;
        let resH = y - block.offsetTop;
        let resX = resW / percW;
        let resY = resH / percH;
        loop.style.transform = `translateY(-${resY}%) translateX(-${resX}%)`;
        loop.style.left = `${resX}%`;
        loop.style.top = `${resY}%`;
        loop.style.transition = 'none'
    }

    function shadowingDefault(){
        const loop = document.querySelector('.careersCollage__img1366');
        loop.style.transform = `translateY(-50%) translateX(-50%)`;
        loop.style.left = `50%`;
        loop.style.top = `50%`;
        loop.style.transition = 'ease 1s'
    }

    return (
        <div
            className='careersCollage1366'
            onMouseMove={e => shadowingAnimation(e, e.target.parentNode)}
            onMouseLeave={e => shadowingDefault()}
           >
            
          <img className='careersCollage__img1366' src={collage} alt="Nitrix family <3" />
          
        </div>
    );
};

export default Collage1366;
