import React, { useState, useEffect } from 'react';
import './IndustriesCard.css';
import { useDispatch } from 'react-redux';
import { setIndustry } from '../../../store/slices/industries';



const IndustriesCard = ({ title, number, text, cover, statusCard }) => {

    const dispatch = useDispatch();

    const [animationCoverStyle, setAnimationCoverStyle] = useState('');

    useEffect(() => {
        if (statusCard === 'open') {
            setAnimationCoverStyle('animation-cover-top');
        }
        if (statusCard === 'closed') {
            setAnimationCoverStyle('animation-cover-down');
        }
    }, [statusCard]);

    return (
        <>
            <div className='industries-card-desktop'  >

                <div
                    onMouseLeave={() => {
                        if (statusCard == 'open') {

                        } else {
                            setAnimationCoverStyle('animation-cover-down')
                        }

                    }}
                    style={{ position: 'relative' }} onClick={() => dispatch(setIndustry(number))}>

                    <div
                        className={`industries-card ${animationCoverStyle}`}
                        style={{ position: 'relative', zIndex: 1 }}
                        onMouseEnter={() => setAnimationCoverStyle('animation-cover-top')}
                    >

                        <img src={cover} alt="NitrixCover" />

                    </div>

                    <div
                        className="industries-card"
                        style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }}
                    >

                        <div className="industries-card_wrapper">
                            <h5 className="industries-card_title">{title}</h5>
                            <span className="industries-card_number">{number}</span>
                        </div>
                        <div className="industries-card-inner">

                            {text.map((text, index) => (
                                <p className="industries-card_text" key={index}>{text}</p>
                            ))}

                        </div>
                    </div>
                </div>

            </div>



            <div className='industries-card-mobile'>

                <div className="industries-card">
                    <div className="industries-card_wrapper">
                        <h5 className="industries-card_title">{title}</h5>
                        <span className="industries-card_number">{number}</span>
                    </div>

                    <div className="industries-card-inner">

                        {text.map((text, index) => (
                            <p className="industries-card_text" key={index}>{text}</p>
                        ))}
                    </div>
                </div>

            </div>
        </>
    )
}

export default IndustriesCard;