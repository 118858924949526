import './CareersVacancy.css';
import VacancyCard from './VacancyCard/VacancyCard';
import { useCheckActiveVacancies } from '../../hooks/useCheckActiveVacancies';



const CareersVacancy = () => {

    const activeVacancies = useCheckActiveVacancies();


    return (
        <div className="careers-vacancy">

            {(activeVacancies !== null && activeVacancies !== undefined && activeVacancies.length > 0)
                ? (<>
                    <div className="careers-vacancy-content">
                        <h2 className="careers-vacancy-title">We are Hiring</h2>
                        <p className="careers-vacancy-text">At Nitrix, we value quality and competence. That's why our team consists of the people who love their work and strive for improvement.</p>
                        <a href="#contact"><button className="careers-vacancy-button">Join Us</button></a>
                    </div>

                    <div className="vacancy-card">
                        {activeVacancies.map((vacancy) => {
                            return vacancy.visibility && (
                                <VacancyCard
                                    name={vacancy.name}
                                    languageLvl={vacancy.languageLvl}
                                    city={vacancy.city}
                                    experince={vacancy.experince}
                                    content={vacancy.content}
                                    key={vacancy.name}
                                    id={vacancy._id}
                                />
                            )
                        })}
                    </div>
                </>)

                : (<>
                    <div className="careers-vacancy-content-when-no-vacancy">
                        <h2 className="careers-vacancy-title">No Suitable vacancy?</h2>
                        <p className="careers-vacancy-text">Then drop us a line and <br /> we’ll see what we can do!</p>
                        <a href="#contact"><button className="careers-vacancy-button">Drop Us<br />a line</button></a>
                    </div>

                    <div className="vacancy-card-when-no-vacancy-wrapper">
                        <div className='vacancy-card-when-no-vacancy'>
                            <p className='vacancy-card-when-no-vacancy-title'>Oops! Come back later :)</p>
                            <hr className='vacancy-card-vertical-line-mobile' />
                        </div>
                    </div>
                </>)}

        </div>
    )
}

export default CareersVacancy;