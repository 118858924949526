import './PortfolioContent.css';
import { TEXTS } from '../../texts';



const PortfolioContent = () => {

    return (
        <div className="portfolio-content">
            <h1 className="portfolio-title">{TEXTS.casesTitle}</h1>
            <p className="portfolio-text">{TEXTS.casesSubtitle}</p>
        </div>
    )
}

export default PortfolioContent;