import { useEffect } from "react";
import { useSelector } from 'react-redux';



export const withCookiesCheker = (Component) => {

    return function (props) {

        const { cookies } = useSelector(state => state.cookies);

        useEffect(() => {
            if (localStorage.getItem('acceptAllCookies') == null) {
                document.querySelector('.cookies-wrapper').style.display = 'flex';
            }
            if (localStorage.getItem('acceptAllCookies') == 'true' || cookies == true) {
                document.querySelector('.cookies-wrapper').style.display = 'none';
            }
        }, []);

        return <Component {...props} />
    }
}
