import './OutsourceProcess.css';
import { TEXTS } from '../../texts';
import ServiceOutsource1Img from '../../img/service/ServiceOutsource1Img.webp';
import ServiceOutsource2Img from '../../img/service/ServiceOutsource2Img.webp';
import ServiceOutsource3Img from '../../img/service/ServiceOutsource3Img.webp';
import ServiceOutsource4Img from '../../img/service/ServiceOutsource4Img.webp';
import { useState } from 'react';



const OutsourceProcess = () => {

  const [s01, setS01] = useState('outsource-process-subtitle-active')
  const [s02, setS02] = useState('')
  const [s03, setS03] = useState('')
  const [s04, setS04] = useState('')

  const s01Handler = () => {
    setS01('outsource-process-subtitle-active')
    setS02('')
    setS03('')
    setS04('')
  }
  const s02Handler = () => {
    setS01('')
    setS02('outsource-process-subtitle-active')
    setS03('')
    setS04('')
  }

  const s03Handler = () => {
    setS01('')
    setS02('')
    setS03('outsource-process-subtitle-active')
    setS04('')
  }

  const s04Handler = () => {
    setS01('')
    setS02('')
    setS03('')
    setS04('outsource-process-subtitle-active')
  }

  return (

    <div className="outsource-process">

      <h2 className="outsource-process-title">{TEXTS.outsourceProcessTitle}</h2>

      <div className="outsource-process-wrapper">
        <div className="outsource-process-card outsource-process-card-tablet1">
          <h4 className={`outsource-process-subtitle ${s01}`} onClick={s01Handler}>{TEXTS.number01S}<span className="outsource-process-span">{TEXTS.outsourceProcessTitle_1}</span></h4>
          <h4 className={`outsource-process-subtitle ${s02}`} onClick={s02Handler}>{TEXTS.number02S}<span className="outsource-process-span">{TEXTS.outsourceProcessTitle_2}</span></h4>
          <h4 className={`outsource-process-subtitle ${s03}`} onClick={s03Handler}>{TEXTS.number03S}<span className="outsource-process-span">{TEXTS.outsourceProcessTitle_3}</span></h4>
          <h4 className={`outsource-process-subtitle ${s04}`} onClick={s04Handler}>{TEXTS.number04S}<span className="outsource-process-span">{TEXTS.outsourceProcessTitle_4}</span></h4>
        </div>
        {s01 && (
          <>
            <div className="outsource-process-card outsource-process-card-tablet2">
              <p className="outsource-process-text">{TEXTS.outsourceProcessText_1}</p>
            </div>
            <div className="outsource-process-card outsource-process-card-tablet3">
              <img className="outsource-process-img" src={ServiceOutsource1Img} alt="Outsource" />
            </div>
          </>
        )}
        {s02 && (
          <>
            <div className="outsource-process-card outsource-process-card-tablet2">
              <p className="outsource-process-text">{TEXTS.outsourceProcessText_2}</p>
            </div>
            <div className="outsource-process-card outsource-process-card-tablet3">
              <img className="outsource-process-img" src={ServiceOutsource2Img} alt="Outsource" />
            </div>
          </>
        )}
        {s03 && (
          <>
            <div className="outsource-process-card outsource-process-card-tablet2">
              <p className="outsource-process-text">{TEXTS.outsourceProcessText_3}</p>
            </div>
            <div className="outsource-process-card outsource-process-card-tablet3">
              <img className="outsource-process-img" src={ServiceOutsource3Img} alt="Outsource" />
            </div>
          </>
        )}
        {s04 && (
          <>
            <div className="outsource-process-card outsource-process-card-tablet2">
              <p className="outsource-process-text">{TEXTS.outsourceProcessText_4}</p>
            </div>
            <div className="outsource-process-card outsource-process-card-tablet3">
              <img className="outsource-process-img" src={ServiceOutsource4Img} alt="Outsource" />
            </div>
          </>
        )}
      </div>
    </div>

  )
}

export default OutsourceProcess;