import React from 'react';
import './OutsourceFaq.css';
import { TEXTS } from '../../texts';
import FAQAccordion from '../FAQAccordion/FAQAccordion';



const OutsourceFaq = () => {


    const accordions = [
        {
            question: TEXTS.outsourceFaqQuestion_1,
            text: TEXTS.outsourceFaqText_1,
        },
        {
            question: TEXTS.outsourceFaqQuestion_2,
            text: TEXTS.outsourceFaqText_2,
        },
        {
            question: TEXTS.outsourceFaqQuestion_3,
            text: TEXTS.outsourceFaqText_3,
        }
    ]



    return (

        <div className="outsource-faq">
            <div className="outsource-faq-wrapper">

                <h2 className="outsource-faq-title">{TEXTS.FAQ}</h2>

                {accordions.map((accordion, index) => (
                    <FAQAccordion
                        key={index}
                        question={accordion.question}
                        text={accordion.text}
                        arrItems={accordion.arrItems}
                    />
                ))}

            </div>
        </div>

    )
}

export default OutsourceFaq;