import { useEffect } from 'react';
import PortfolioContent from "../components/PortfolioContent/PortfolioContent";
import PortfolioCase from "../components/PortfolioCase/PortfolioCase";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { withCookiesCheker } from '../HOCs/withCookiesCheker';
import { useScrollToId } from '../hooks/useScrollToId';



const Cases = () => {

    useEffect(() => {
        let arrow = document.getElementById("arrowScroll");
        arrow.style.display = 'none';
    }, []);

    const scrollToId = useScrollToId();

    return (
        <>
            <Header />
            <PortfolioContent />
            <PortfolioCase />
            <Footer />
        </>
    )
}

export default withCookiesCheker(Cases);