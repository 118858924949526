import { useRef, useState, useEffect } from 'react';
import style from './Header.module.css';
import { TEXTS } from '../../texts';
import { NavLink, useLocation, } from 'react-router-dom';
import logo from '../../img/header/logo.svg';
import logoLite from '../../img/header/logo_lite.svg';
import contactBtn from '../../img/header/contact-btn.svg';
import pencilBtn from '../../img/header/pencil_button_for_black_mobile.svg';

import { useDispatch } from 'react-redux';
import { switchFromPrivacyToHome } from '../../store/slices/switchFromPrivacyToHomeSlice';



const Header = () => {

    const menu = useRef();
    const burger = useRef();
    const location = useLocation();
    const dispatch = useDispatch();

    const [mobileMenuToggle, setMobileMenuToggle] = useState(false);
    const [changeLogo, setChangeLogo] = useState(false);


    const changingHeaderStyleOnHomepageOnMobile = () => {
        if (location.pathname === '/') {
            document.getElementById('mobileWrapper').classList.add(style.mobileWrapper__black);
            document.getElementById('burgerMenu').classList.remove(style.burgerMenu);
            document.getElementById('burgerMenu').classList.add(style.burgerMenu__white);
            setChangeLogo(true)
        }
    }

    useEffect(() => {
        if (location.pathname === '/privacy') {
            dispatch(switchFromPrivacyToHome(true));
        } else {
            dispatch(switchFromPrivacyToHome(false));
        }

        changingHeaderStyleOnHomepageOnMobile();
    }, [])



    useEffect(() => {

        let body = document.getElementsByTagName('body');

        if (mobileMenuToggle) {
            burger.current.classList.add(style.active);
            menu.current.classList.add(style.active);
            body[0].classList.add('globalClass__bodyOverflowHidden');
        } else {
            burger.current.classList.remove(style.active);
            menu.current.classList.remove(style.active);
            body[0].classList.remove('globalClass__bodyOverflowHidden');
        }
    }, [mobileMenuToggle]);



    const setActive = ({ isActive }) => isActive ? { color: '#28C0E2' } : {}

    return (
        <div id='arrow-up' style={{ position: 'relative' }}>

            <div className={style.container} >
                <div className={style.wrapper}>
                    <NavLink to="/">
                        <img
                            src={logo}
                            alt="Nitrix"
                            className={style.logo}
                            onClick={location.pathname === '/privacy' ? () => dispatch(switchFromPrivacyToHome(false)) : null}
                        />
                    </NavLink>
                    <div className={style.navWrapper}>
                        <nav>
                            <ul className={style.navigationList}>
                                <li className={style.nav_item}><NavLink to="/company" style={setActive}>{TEXTS.COMPANY}</NavLink></li>
                                <li className={style.nav_item}><NavLink to="/cases" style={setActive}>{TEXTS.CASES}</NavLink></li>
                                <li className={style.trigger}>
                                    <span ><NavLink to="/services" style={setActive} id='servicesHeader'>{TEXTS.SERVICES}</NavLink></span>
                                    <div className={style.secondLayer}>

                                        <hr className={style.divider} />

                                        <div className={style.secondLayerWrapper}>
                                            <NavLink className={style.headerCard} to="/services/ddt">
                                                <div>
                                                    <div className={style.cardTitle}>{TEXTS.DEDICATED_TEAM}</div>
                                                    <div className={style.cardText}>{TEXTS.ddtShortDiscriptionInHeader}</div>
                                                </div>
                                            </NavLink>
                                            <NavLink className={style.headerCard} to="/services/outstaff">
                                                <div>

                                                    <div className={style.cardTitle}>{TEXTS.OUTSTAFF}</div>
                                                    <div className={style.cardText}>{TEXTS.outstaffShortDiscriptionInHeader}</div>
                                                </div>
                                            </NavLink>
                                            <NavLink className={style.headerCard} to="/services/outsource">
                                                <div>

                                                    <div className={style.cardTitle}>{TEXTS.OUTSOURCE}</div>
                                                    <div className={style.cardText}>{TEXTS.outsourceShortDiscriptionInHeader}</div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </li>
                                <li className={style.nav_item}><NavLink to="/careers" style={setActive}>{TEXTS.CAREERS}</NavLink></li>
                                <li className={style.nav_item}>
                                    {location.pathname === '/privacy'
                                        ? <NavLink to="/"> {TEXTS.CONTACT_US} </NavLink>
                                        : <a href={'#contact'}> {TEXTS.CONTACT_US} </a>
                                    }
                                </li>
                            </ul>
                        </nav>

                    </div>

                </div>
            </div>
            <div style={{ position: 'relative', margin: '0 auto', maxWidth: '1300px', height: '0px' }}>
                <a href='#arrow-down' className={style.scrollDown} id="arrowScroll">
                    {TEXTS.scroll}
                </a>
            </div>

            <div className={style.mobileWrapper} id='mobileWrapper'>
                <div className={style.mobileTop}>
                    <NavLink to="/">
                        <img
                            src={changeLogo ? logoLite : logo}
                            alt="Nitrix"
                            className={style.logo}
                            onClick={location.pathname === '/privacy' ? () => dispatch(switchFromPrivacyToHome(false)) : null}
                        />
                    </NavLink>
                    <a href="#contact" id='pencil-mobile'>
                        <img src={contactBtn} alt="Contact Us" className={style.contactBtn} />
                    </a>
                    <div className={style.burgerMenu} id='burgerMenu' onClick={() => setMobileMenuToggle(!mobileMenuToggle)} ref={burger}></div>
                </div>
                <div className={style.mobileMenu} ref={menu} >

                    <div className={style.mobileHeaderContainer}>

                        <div className={style.mobileHeader}>
                            <NavLink to="/" onClick={() => setMobileMenuToggle(!mobileMenuToggle) && location.pathname === '/privacy' ? () => dispatch(switchFromPrivacyToHome(false)) : null}>
                                <img src={logoLite} className={style.logo} alt="Nitrix" />
                            </NavLink>
                            <a href="#contact" onClick={() => setMobileMenuToggle(!mobileMenuToggle)} id="pencil-mobile-menu">
                                <img src={contactBtn} className={style.contactBtnMenuList} alt="Contact Us" />
                            </a>
                        </div>

                        <div className={style.mobileMenuList}>
                            <ul>
                                <li onClick={() => setMobileMenuToggle(!mobileMenuToggle)}><NavLink to="/company">{TEXTS.COMPANY} </NavLink></li>
                                <li onClick={() => setMobileMenuToggle(!mobileMenuToggle)}><NavLink to="/cases">{TEXTS.CASES} </NavLink></li>
                                <li onClick={() => setMobileMenuToggle(!mobileMenuToggle)}><NavLink to="/services">{TEXTS.SERVICES} </NavLink></li>
                                <li onClick={() => setMobileMenuToggle(!mobileMenuToggle)}><NavLink to="/careers">{TEXTS.CAREERS} </NavLink></li>
                                <li onClick={() => setMobileMenuToggle(!mobileMenuToggle)}>
                                    {location.pathname === '/privacy'
                                        ? <NavLink to="/">{TEXTS.CONTACT_US}</NavLink>
                                        : <a href={'#contact'} >{TEXTS.CONTACT_US}</a>
                                    }
                                </li>
                            </ul>
                        </div>
                        <div className={style.mobileLinks}>
                            <div className={style.mobileLinksWrapper}>
                                <a href="https://www.linkedin.com/company/nitrix.io/"> {TEXTS.LINKEDIN}</a>
                                <a href="mailto:hello@nitrix.io">{TEXTS.EMAIL}</a>
                                <a href="https://www.upwork.com/ag/nitrix/"> {TEXTS.UPWORK}</a>
                                <a href="https://www.instagram.com/nitrix.soft/">{TEXTS.INSTAGRAM}</a>
                                <a href="skype:live:ba_1545?chat"> {TEXTS.SKYPE}</a>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default Header;
