import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    switchFromPrivacyToHome: false
}

const switchFromPrivacyToHomeSlice = createSlice({
    name: 'switchFromPrivacyToHome',
    initialState,
    reducers: {
        switchFromPrivacyToHome(state, action) {
            state.switchFromPrivacyToHome = action.payload
        }
    }
})


export const { switchFromPrivacyToHome } = switchFromPrivacyToHomeSlice.actions;

export const switchFromPrivacyToHomeReducer = switchFromPrivacyToHomeSlice.reducer;