import React, { useState } from 'react';
import './VacancyCard.css';
import cursor from '../../../img/careers/press_cursor.svg';
import DOMPurify from 'dompurify';



const VacancyCard = ({ name, languageLvl, city, experince, content, id }) => {

    const [ariaExpanded, setAriaExpanded] = useState(false);

    const handleAccordion = () => {
        setAriaExpanded(!ariaExpanded);
    }

    return (
        <div className="accordion-vacancy-wrapper-content" id={`divId${id}`} >


            <button className={`accordion-vacancy-buttons`}
                aria-expanded={ariaExpanded}
                aria-hidden="true"
                onClick={handleAccordion}
            >

                < img src={cursor} alt="" className='accordion-vacancy-cursor' id={`cursorId${id}`} />

                <h4 className='accordion-vacancy-title'>{name}</h4>
                <ul className='accordion-vacancy-shortDescription'>
                    <li className="accordion-shortDescription-item">{languageLvl}</li>
                    <li className="accordion-shortDescription-item">{experince}</li>
                    <li className="accordion-shortDescription-item">{city}</li>
                </ul>

            </button>



            <div className="accordion-vacancy-content">
                <div className="accordion-vacancy-text">
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default VacancyCard;