import './OutstaffProcess.css';
import { TEXTS } from '../../texts';
import OutstaffProcessPngImg2 from '../../img/service/OutstaffProcessPngImg2.webp';


const OutstaffProcess = () => {


    return (
        <div className="outstaff-process" id="arrow-down">

            <h2 className="outstaff-process-inform_subtitle_mobile">{TEXTS.outstaffProcessTitle}</h2>

            <img className='outstaff-process-img' src={OutstaffProcessPngImg2} alt="" />




            <div className="outstaff-process-inform" >

                <h4 className="outstaff-process-inform_subtitle">{TEXTS.outstaffProcessTitle}</h4>

                <div className="outstaff-process-step">
                    <p className="outstaff-process-step_number">{TEXTS.number01}</p>
                    <h3 className="outstaff-process-step_title">{TEXTS.outstaffProcessStepTitle_1}</h3>
                    <p className="outstaff-process-step_text">{TEXTS.outstaffProcessStepText_1}</p>
                </div>
                <div className="outstaff-process-step">
                    <p className="outstaff-process-step_number">{TEXTS.number02}</p>
                    <h3 className="outstaff-process-step_title">{TEXTS.outstaffProcessStepTitle_2}</h3>
                    <p className="outstaff-process-step_text">{TEXTS.outstaffProcessStepText_2}</p>
                </div>
                <div className="outstaff-process-step">
                    <p className="outstaff-process-step_number" >{TEXTS.number03}</p>
                    <h3 className="outstaff-process-step_title">{TEXTS.outstaffProcessStepTitle_3}</h3>
                    <p className="outstaff-process-step_text">{TEXTS.outstaffProcessStepText_3}</p>
                </div>
                <div className="outstaff-process-step">
                    <p className="outstaff-process-step_number">{TEXTS.number04}</p>
                    <h3 className="outstaff-process-step_title">{TEXTS.outstaffProcessStepTitle_4}</h3>
                    <p className="outstaff-process-step_text">{TEXTS.outstaffProcessStepText_4}</p>
                </div>

            </div>
        </div>
    )
}

export default OutstaffProcess;