import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    cookies: false
}

const cookiesSlice = createSlice({
    name: 'cookies',
    initialState,
    reducers: {
        acceptAllCookies(state, action) {
            let newState = state.cookies
            newState = action.payload
            state.cookies = newState
        }
    }

})

export const { acceptAllCookies } = cookiesSlice.actions;

export const cookiesReducer = cookiesSlice.reducer;