import './DDTPhoto.css';
import DDTPhotoPngImg1 from '../../img/service/ddt-photo-1.webp';
import DDTPhotoPngImg2 from '../../img/service/ddt-photo-2.webp';
import DDTPhotoPngImg3 from '../../img/service/ddt-photo-3.webp';
import DDTPhotoPngImg4 from '../../img/service/ddt-photo-4.webp';
import DDTPhotoPngImg5 from '../../img/service/ddt-photo-5.webp';



const DDTPhoto = () => {



    return (
        <div className="ddt-photo-wrapper">
            <div className="ddt-photo" id="horizontal-scroller">
                <img src={DDTPhotoPngImg1} alt="" className="ddt-photo-img1" />
                <img src={DDTPhotoPngImg2} alt="" className="ddt-photo-img2" />
                <img src={DDTPhotoPngImg3} alt="" className="ddt-photo-img3" />
                <img src={DDTPhotoPngImg4} alt="" className="ddt-photo-img4" />
                <img src={DDTPhotoPngImg5} alt="" className="ddt-photo-img5" />
            </div>
        </div>
    )
}

export default DDTPhoto;