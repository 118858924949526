import style from './ServicesText.module.css';
import Flashlight from '../Flashlight/Flashlight';
import ServicesFlashlightBlackWhite from '../../img/service/ServicesFlashlightBlack&White.webp';
import ServicesFlashlightMobile from '../../img/service/ServicesFlashlightMobile.webp';
import ServicesFlashlightColor from '../../img/service/ServicesFlashlightColor.webp';
import cursor from '../../img/move_cursor_white.svg';



const ServicesText = () => {



    return (
        <>
            <h3 className={style.subtitle}>services</h3>
            <h1 className={style.title}>Software<br />Development Services</h1>

            <div className={style.flashlight__wrapper}>
                <Flashlight
                    imgFront={ServicesFlashlightBlackWhite}
                    imgBack={ServicesFlashlightColor}
                    imgMobile={ServicesFlashlightMobile}
                    imgFrontId='img__services1'
                    imgBackId='img__services2'
                    loopSize={170}
                    cursor={cursor}
                    cursorHeight={120}
                    cursorWidth={120}
                    cursorId='cursor__services'
                    objectPosition={'50% 30%'}
                    mobileObjectPosition={'100% 50%'}
                    newTab={false}
                />
            </div>
        </>
    )
}

export default ServicesText;