import React from 'react';
import style from './ServicesProvide.module.css';
import { TEXTS } from '../../texts';
import OutsourceImg from '../../img/service/service-outsource-img-1365.webp';
import OutstaffImg from '../../img/service/service-outstaff-img-1365.webp';
import DDTImg from '../../img/service/service-ddt-img-1365.webp';



const ServicesProvide = () => {

    return (
        <div className={style.wrapper}>

            <h3 className={style.title}>{TEXTS.servicesProvideTitle}</h3>

            <a
                onClick={() => setTimeout(() => {
                    window.location.href = '/services/outsource'
                }, 400)}
            >
                <div className={style.block}>
                    <img src={OutsourceImg} alt="nitrix_outsource" className={style.block__img} />
                    <div className={style.block__wrapper}>
                        <p className={style.linearAnimation}></p>
                        <h4 className={style.block__title}>Outsource</h4>
                        <p className={style.block__text}>
                            {TEXTS.servicesProvideTextOutsource}
                        </p>
                    </div>
                </div>
            </a>

            <a
                onClick={() => setTimeout(() => {
                    window.location.href = '/services/outstaff'
                }, 400)}
            >
                <div className={style.block_2}>
                    <div className={style.block__wrapper}>
                        <p className={style.linearAnimation}></p>
                        <h4 className={style.block__title}>Outstaff</h4>
                        <p className={style.block__text}>
                            {TEXTS.servicesProvideTextOutstaff}
                        </p>
                    </div>
                    <img src={OutstaffImg} alt="nitrix_outstaff" className={style.block__img} />
                </div>
            </a>

            <a
                onClick={() => setTimeout(() => {
                    window.location.href = '/services/ddt'
                }, 400)}
            >
                <div className={style.block}>
                    <img src={DDTImg} alt="nitrix_ddt" className={style.block__img} />
                    <div className={style.block__wrapper}>
                        <p className={style.linearAnimation}></p>
                        <h4 className={style.block__title}>Dedicated <br /> Development Team</h4>
                        <p className={style.block__text}>
                            {TEXTS.servicesProvideTextDDT}
                        </p>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default ServicesProvide;