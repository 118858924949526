import React from 'react';



const PresentationText = () => {

    return (
        <div className='presentation'>
            <a href="/cases">
                <button className="presentation-button"  >
                    View <br /> work
                </button>
            </a>
            <h2 className="presentation-title-right">Professional</h2>
            <h2 className="presentation-title-left">Software</h2>
            <h2 className="presentation-title-right">Development</h2>
        </div>
    )
}

export default PresentationText;