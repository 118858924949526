import './OutsourceText.css';
import Flashlight from '../Flashlight/Flashlight';
import OutsourceFlashlightBlackWhite from '../../img/service/outsource/OutsourceFlashlightBlack&White.webp';
import OutsourceFlashlightMobile from '../../img/service/outsource/OutsourceFlashlightMobile.webp';
import OutsourceFlashlightColor from '../../img/service/outsource/OutsourceFlashlightColor.webp';
import cursor from '../../img/move_cursor_white.svg';



const OutsourceText = () => {



  return (
    <section>
      <h3 className="outsource-subtitle">IT Outsourcing Services</h3>
      <h1 className="outsource-title">Outsource IT Services with N<span className="outsource-title-blue">it</span>rix team</h1>

      <div className="outsource-flashlight-wrapper">
        <Flashlight
          imgFront={OutsourceFlashlightBlackWhite}
          imgBack={OutsourceFlashlightColor}
          imgMobile={OutsourceFlashlightMobile}
          imgFrontId='img__outsource1'
          imgBackId='img__outsource2'
          loopSize={170}
          cursor={cursor}
          cursorHeight={120}
          cursorWidth={120}
          cursorId='cursor__outsource'
          objectPosition={'50% 60%'}
          mobileObjectPosition={'50% 50%'}
          newTab={false}
        />
      </div>

    </section>
  )
}

export default OutsourceText;