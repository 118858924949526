import './OutsourcePros.css';
import { TEXTS } from '../../texts';



const OutsourcePros = () => {



    return (
        <div className="outsource-pros">
            <div className="outsource-pros-wrapper">

                <h2 className="outsource-pros-title">Pros of the<br />IT Outsourcing</h2>

                <div className="outsource-pros-card linear-hover">
                    <div className="linear-hover-line"></div>
                    <div className="outsource-pros-card-wrapper">
                        <h3 className="outsource-pros-card_title">{TEXTS.outsourceProsCardTitle_1}</h3>
                        <span className="outsource-pros-card_number">{TEXTS.number01}</span>
                    </div>
                    <p className="outsource-pros-card_text">{TEXTS.outsourceProsCardText_1}</p>
                </div>

                <div className="outsource-pros-card linear-hover">
                    <div className="linear-hover-line"></div>
                    <div className="outsource-pros-card-wrapper">
                        <h3 className="outsource-pros-card_title">{TEXTS.outsourceProsCardTitle_2}</h3>
                        <span className="outsource-pros-card_number">{TEXTS.number02}</span>
                    </div>
                    <p className="outsource-pros-card_text">{TEXTS.outsourceProsCardText_2}</p>
                </div>

                <div className="outsource-pros-card linear-hover">
                    <div className="linear-hover-line"></div>
                    <div className="outsource-pros-card-wrapper">
                        <h3 className="outsource-pros-card_title">{TEXTS.outsourceProsCardTitle_3}</h3>
                        <span className="outsource-pros-card_number">{TEXTS.number03}</span>
                    </div>
                    <p className="outsource-pros-card_text">{TEXTS.outsourceProsCardText_3}</p>
                </div>

                <div className="outsource-pros-card-last linear-hover-last">
                    <div className="linear-hover-line-last"></div>
                    <div className="outsource-pros-card-wrapper-last">
                        <h3 className="outsource-pros-card_title">{TEXTS.outsourceProsCardTitle_4}</h3>
                        <span className="outsource-pros-card_number">{TEXTS.number04}</span>
                    </div>
                    <p className="outsource-pros-card_text-last">{TEXTS.outsourceProsCardText_4}</p>
                </div>

            </div>
        </div>
    )
}

export default OutsourcePros;