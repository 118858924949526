import React from 'react';
import './OutstaffFaq.css';
import { TEXTS } from '../../texts';
import FAQAccordion from '../FAQAccordion/FAQAccordion';



const OutstaffFaq = () => {


    const accordions = [
        {
            question: 'What do IT Outstaffing and Outsourcing mean for clients?',
            text: 'Outsourcing and outstaffing - both concepts denote a special kind of relationship between a company and employees outside the main working staff. They play an important role in the development of the organization and the implementation of individual processes. These services help to reduce costs and time spent on searching for a candidate and quickly closing needs for a specific project.',
        },
        {
            question: 'What tasks does IT Outsourcing cover for my business?',
            text: '',
            arrItems: [
                {
                    text: 'Search and selection of a professional team for a certain period to complete the tasks.'
                },
                {
                    text: 'Staff management.'
                },
                {
                    text: 'Payroll, taxes, and contributions calculation.'
                },
                {
                    text: 'Preparing documentation and required reporting.'
                }
            ]
        },
        {
            question: 'Can I hire one developer or do I need to hire the whole team?',
            text: 'You can form your team. Therefore, if one employee is enough for your project, we will gladly provide you with this service. Specify your needs and we will select the best candidate.',
        }
    ]


    return (
        <div className="outstaff-faq">

            <div className="outstaff-faq-wrapper">
                <h2 className="outstaff-faq-title">{TEXTS.FAQ}</h2>
            </div>

            {accordions.map((accordion, index) => (
                <FAQAccordion
                    key={index}
                    question={accordion.question}
                    text={accordion.text}
                    arrItems={accordion.arrItems}
                />
            ))}

        </div>
    )
}

export default OutstaffFaq;