import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";



const CheckRoles = ({ children }) => {

    const { user } = useSelector(state => state.auth);

    if (!user?.roles?.includes('ADMIN')) {
        return <Navigate to='/admin_panel/' />
    }

    return !user ? null : children;
};

export default CheckRoles;