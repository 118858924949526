import style from './OutstaffAnother.module.css';
import AnotherServicesCard from '../AnotherServicesCard/AnotherServicesCard';
import { TEXTS } from '../../texts';
import DDTImgDesktop from '../../img/service/service-ddt-img.webp';
import DDTImgLaptop from '../../img/service/service-ddt-img-1365.webp';
import DDTImgMobile from '../../img/service/service-ddt-img-768.webp';
import OutsourceImgDesktop from '../../img/service/service-outsource-img.webp';
import OutsourceImgLaptop from '../../img/service/service-outsource-img-1365.webp';
import OutsourceImgMobile from '../../img/service/service-outsource-img-768.webp';



const OutstaffAnother = () => {

    const anotherServices = [
        {
            href: '/services/ddt',
            title: TEXTS.anotherServicesDDTTitle,
            text: TEXTS.anotherServicesDDTText,
            imgDesktop: DDTImgDesktop,
            imgLaptop: DDTImgLaptop,
            imgMobile: DDTImgMobile,
            hoverTitle: TEXTS.anotherServicesDDTHoverTitle,
        },
        {
            href: '/services/outsource',
            title: TEXTS.anotherServicesOutsourceTitle,
            text: TEXTS.anotherServicesOutsourceText,
            imgDesktop: OutsourceImgDesktop,
            imgLaptop: OutsourceImgLaptop,
            imgMobile: OutsourceImgMobile,
            hoverTitle: TEXTS.anotherServicesOutsourceHoverTitle
        }]

    return (
        <div className={style.wrapper}>

            <h2 className={style.title}>{TEXTS.anotherServicesTitle}</h2>

            <div className={style.container}>

                {anotherServices.map((card, index) => (
                    <AnotherServicesCard card={card} key={index} />
                ))}

            </div>
        </div>
    )
}

export default OutstaffAnother;