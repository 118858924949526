import React from 'react';
import style from './FooterPrivacy.module.css';
import { TEXTS } from '../../texts';



const FooterPrivacy = () => {

    const getYear = new Date().getFullYear();

    return (
        <div className={style.wrapper}>
            <ul className={style.list}>
                <li className={style.item}><a href="/">{TEXTS.NITRIX}</a></li>
                <li> </li>
                <li className={style.item}><a href="/privacy">{TEXTS.privacy}</a></li>
            </ul>
            <div>
                <p className={style.year}>© {getYear}</p>
            </div>
        </div>
    )
}

export default FooterPrivacy;