import './CareersText.css';
import { TEXTS } from '../../texts';

import Collage1920 from './Collage1920/Collage1920';
import Collage1366 from './Collage1366/Collage1366';
import bannerTablet from '../../img/careers/collage-1366.webp';
import bannerMobile from '../../img/careers/collage-768.webp';



const CareersText = () => {



  return (
    <section>

      <div className="careers-text">
        <h3 className="careers-subtitle">{TEXTS.careersSubtitle}</h3>
        <h1 className="careers-title">{TEXTS.careersTitle}</h1>
      </div>

      <div className='collage1920'><Collage1920 /></div>
      <div className='collage1366'><Collage1366 /></div>
      <div className="collage_tablet"><img src={bannerTablet} alt="Nitrix team" /></div>
      <div className="collage_mobile"><img src={bannerMobile} alt="Nitrix team" /></div>

    </section>
  )
}

export default CareersText;