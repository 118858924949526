import React, { useState } from 'react';
import './Portfolio.css';
import { TEXTS } from '../../texts';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper';
import cursor from '../../img/portfolio-card-cursor.webp';
import PortfolioCard from './PortfolioCard/PortfolioCard';
import JoynBanner from '../../img/portfolio/main/Joyn.webp';
import JoynBannerUnderCover from '../../img/portfolio/main/JoynUnderCover.webp';
import StartupBanner from '../../img/portfolio/main/Startup.webp';
import StartupBannerUnderCover from '../../img/portfolio/main/StartupUnderCover.webp';
import AquaPalBanner from '../../img/portfolio/main/AquaPal.webp';
import AquaPalBannerUnderCover from '../../img/portfolio/main/AquaPalUnderCover.webp';




const Portfolio = () => {

  const [id001, setId001] = useState(true);
  const [id002, setId002] = useState(false);
  const [id003, setId003] = useState(false);

  const [resX, setResX] = useState();
  const [resY, setResY] = useState();


  let portfolioCases = [
    {
      title: TEXTS.AquaPalAppDescription,
      subtitle: TEXTS.AquaPal,
      desc: [TEXTS.DESIGN, TEXTS.FLUTTER, TEXTS.QA],
      text: TEXTS.AquaPalShortDescription,
      id: id001,
      bannerMobile: AquaPalBanner,
      banner: AquaPalBanner,
      bannerUnderCover: AquaPalBannerUnderCover,
      href: '/cases/aqua-pal'
    },
    {
      title: TEXTS.JoynAppDescription,
      subtitle: TEXTS.Joyn,
      desc: [TEXTS.FLUTTER, TEXTS.QA],
      text: TEXTS.JoynShortDescription,
      id: id002,
      bannerMobile: JoynBanner,
      banner: JoynBanner,
      bannerUnderCover: JoynBannerUnderCover,
      href: '/cases/joyn'
    },
    {
      title: TEXTS.StartupShowAppDescription,
      subtitle: TEXTS.StartupShow,
      desc: [TEXTS.iOS, TEXTS.ANDROID, TEXTS.QA],
      text: TEXTS.StartupShowShortDescription,
      id: id003,
      bannerMobile: StartupBanner,
      banner: StartupBanner,
      bannerUnderCover: StartupBannerUnderCover,
      href: '/cases/startup-show'
    }
  ]

  const [href, setHref] = useState(portfolioCases[0].href);


  const loopAnimation = (e) => {

    const cursor = document.querySelector('.portfolio-cursor');
    const div = document.querySelector('.portfolio-wrapper');

    let x = e.pageX;
    let y = e.pageY;

    let percW = div.offsetWidth / 100;
    let percH = div.offsetHeight / 100;
    let resW = x - div.offsetLeft;
    let resH = y - div.offsetTop;

    setResX(resW / percW);
    setResY(resH / percH);

    cursor.style.top = `${resY}%`;
    cursor.style.left = `${resX}%`;
    cursor.style.width = `150px`;
    cursor.style.height = `150px`;
    cursor.classList.add('active');
  }

  const animationStart = () => {

    const cursor = document.querySelector('.portfolio-cursor');
    cursor.style.transition = 'ease .0s';
    cursor.classList.add('portfolio-cursor-start');

    setTimeout(() => {
      cursor.classList.remove('portfolio-cursor-start');
    }, 500);
  }

  const animationEnd = () => {
    const cursor = document.querySelector('.portfolio-cursor');
    cursor.style.transition = 'ease .2s';
    cursor.style.width = `0px`;
    cursor.style.height = `0px`;
  }



  return (
    <>
      <div className="portfolio-wrapper portfolio-desktop" id="arrow-down">

        <img src={cursor} alt="" className='portfolio-cursor' />

        <div className="portfolio" id="arrow-down"
          onMouseMove={e => loopAnimation(e)}
          onMouseEnter={animationStart}
          onMouseLeave={animationEnd}
          onClick={() => window.location.href = href}
        >

          <Swiper
            modules={[Mousewheel]}
            speed={300}
            direction={'vertical'}
            onSlideChange={(slide) => {
              if (slide.realIndex == 0) {
                setId001(true);
                setId003(false);
                setId002(false);
                setHref(portfolioCases[0].href)
                setTimeout(() => {
                  slide.params.mousewheel.releaseOnEdges = true;
                }, 800)
              }
              if (slide.realIndex == 1) {
                setId001(false);
                setId003(false);
                setId002(true);
                setHref(portfolioCases[1].href)
                slide.params.mousewheel.releaseOnEdges = false;
              }
              if (slide.realIndex == 2) {
                setId001(false);
                setId003(true);
                setId002(false);
                setHref(portfolioCases[2].href)
                setTimeout(() => {
                  slide.params.mousewheel.releaseOnEdges = true;
                }, 800)
              }
            }}
            mousewheel={{
              sensitivity: 0,
              releaseOnEdges: true,
              thresholdDelta: 40
            }}

            simulateTouch={true}
            touchRatio={0.6}
            breakpoints={{
              1920: {
                slidesPerView: 1,
                spaceBetween: 50
              },
              1366: {
                slidesPerView: 1,
                spaceBetween: 50
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 50
              }
            }}
            className={'portfolio-slider'}
          >

            {portfolioCases.map((portfolioCase, index) => (

              <SwiperSlide key={index} className='portfolio-slide' >

                <PortfolioCard portfolioCase={portfolioCase} />

                <div key={portfolioCase.title} className="portfolio-card" >
                  {portfolioCase.id && (

                    <div className="portfolio-card-wrapper" >
                      <p className="portfolio-card_title">{portfolioCase.title}</p>
                      <h4 className="portfolio-card_subtitle">{portfolioCase.subtitle}</h4>
                      <p className="portfolio-card_desc">
                        {portfolioCase.desc.map((item, index) => (
                          <span style={{ marginRight: '10px' }} key={index}>{item}</span>
                        ))}
                      </p>
                      <p className="portfolio-card_text">{portfolioCase.text}</p>
                      <p className="portfolio-card_comment">{portfolioCase.comment}</p>
                      <div className="portfolio-card_name"><span>{portfolioCase.name}</span><span className="portfolio-card-name2">{portfolioCase.position}</span></div>
                    </div>

                  )}
                </div>
              </SwiperSlide >
            ))}

          </Swiper>

        </div>
      </div>



      <div className='portfolio-mobile'>

        <div className="portfolio-wrapper" id="arrow-down">

          {portfolioCases.map((portfolioCase) => (
            <a href={portfolioCase.href} className="portfolio" key={portfolioCase.title} >

              <img className="portfolio-img" style={{ objectFit: 'cover' }} src={portfolioCase.bannerMobile} alt="SaQure" />

              <div className="portfolio-card">
                <p className="portfolio-card_title">{portfolioCase.title}</p>
                <h4 className="portfolio-card_subtitle">{portfolioCase.subtitle}</h4>
                <p className="portfolio-card_desc">
                  {portfolioCase.desc.map((item, index) => (
                    <span style={{ marginRight: '10px' }} key={index}>{item}</span>
                  ))}
                </p>
                <p className="portfolio-card_text">{portfolioCase.text}</p>
                <p className="portfolio-card_comment">{portfolioCase.comment}</p>
                <div className="portfolio-card_name"><span>{portfolioCase.name}</span><span className="portfolio-card-name2">{portfolioCase.position}</span></div>
              </div>
            </a>
          ))}
        </div>

      </div>

    </>
  )
}

export default Portfolio;
