import './OutsourceWork.css';
import OutsourceSchemaSvgImg from '../../img/service/outsourse-scheme.svg';
import OutsourceSchema1366SvgImg from '../../img/service/outsourse-scheme-1366.svg';
import OutsourceSchema360SvgImg from '../../img/service/outsourse-scheme-360.svg';
import { TEXTS } from '../../texts';



const OutsourceWork = () => {



    return (
        <div className="outsource-work" id="arrow-down">

            <div className="outsource-work-content">
                <h2 className="outsource-work-title">{TEXTS.outsourceWorkTitle}</h2>
                <p className="outsource-work-text">{TEXTS.outsourceWorkText_1}</p>
                <p className="outsource-work-text">{TEXTS.outsourceWorkText_2}</p>
            </div>

            <div className="outsource-communication">
                <picture>
                    <source media="(max-width: 767px)" srcSet={OutsourceSchema360SvgImg} />
                    <source media="(max-width: 1365px)" srcSet={OutsourceSchemaSvgImg} />
                    <source media="(max-width: 1919px)" srcSet={OutsourceSchema1366SvgImg} />
                    <img src={OutsourceSchemaSvgImg} alt="" />
                </picture>
            </div>

        </div>
    )
}

export default OutsourceWork;