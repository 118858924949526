import './DDTInformation.css';
import { TEXTS } from '../../texts';



const DDTInformation = () => {


  return (
    <div className="ddt-information" id="arrow-down">
      <div className="ddt-information-wrapper">

        <h2 className="ddt-information_title">{TEXTS.DDTInformationTitle}</h2>
        <div className="ddt-information_inner">
          <p className="ddt-information_text">{TEXTS.DDTInformationText_1}</p>
          <p className="ddt-information_text">{TEXTS.DDTInformationText_2}</p>
        </div>

      </div>
    </div>
  )
}

export default DDTInformation;