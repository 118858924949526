import './CompanyProcess.css';
import { TEXTS } from '../../texts';
import CompanyProcessPngImg1 from '../../img/company/CompanyProcess1.webp';
import CompanyProcessPngImg2 from '../../img/company/CompanyProcess2.webp';
import { useState, useEffect, useRef } from 'react';



const CompanyProcess = () => {

    const step1 = useRef();
    const step5 = useRef();


    const [img001, setImg001] = useState(true);
    const [img002, setImg002] = useState(false);

    const [step1IsVisible, setStep1IsVisible] = useState();
    const [step5IsVisible, setStep5IsVisible] = useState();

    useEffect(() => {
        const observerForStep1 = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setStep1IsVisible(entry.isIntersecting);
        });
        observerForStep1.observe(step1.current);

        const observerForStep5 = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setStep5IsVisible(entry.isIntersecting);
        });
        observerForStep5.observe(step5.current);

    }, []);

    useEffect(() => {

        if (window.innerWidth > 1366) {
            if (step1IsVisible == true) {
                setImg002(false);
                setImg001(true);
            }
            if (step1IsVisible == false && step5IsVisible == true) {
                setImg002(true);
                setImg001(false);
            }
        }

    }, [step1IsVisible, step5IsVisible]);


    const processes = [
        {
            number: TEXTS.number01S,
            title: TEXTS.QUESTION,
            text: TEXTS.companyProcessQuestionDescription,
            ref: step1
        },
        {
            number: TEXTS.number02S,
            title: TEXTS.RESEARCH,
            text: TEXTS.companyProcessResearchDescription
        },
        {
            number: TEXTS.number03S,
            title: TEXTS.DESIGN,
            text: TEXTS.companyProcessDesignDescription
        },
        {
            number: TEXTS.number04S,
            title: TEXTS.DEVELOP,
            text: TEXTS.companyProcessDevelopDescription
        },
        {
            number: TEXTS.number05S,
            title: TEXTS.TEST,
            text: TEXTS.companyProcessTestDescription,
            ref: step5
        },
        {
            number: TEXTS.number06S,
            title: TEXTS.RELEASE,
            text: TEXTS.companyProcessReleaseDescription,

        },
        {
            number: TEXTS.number07S,
            title: TEXTS.SUPPORT,
            text: TEXTS.companyProcessSupportDescription
        },
    ]

    return (
        <div className="company1-process" >

            <div >
                <div className='company1-process-sticky' >
                    <div style={{ overflow: 'hidden', position: 'relative', borderRadius: '12px' }} >

                        <img
                            src={CompanyProcessPngImg1}
                            alt="nitrix"
                            className="company1-process-img"
                            style={{
                                transform: img001 ? 'translateX(0%)' : 'translateX(0)',
                                transition: 'transform 0.5s',
                                objectFit: 'cover'
                            }} />

                        <img
                            src={CompanyProcessPngImg2}
                            alt="nitrix"
                            className="company1-process-img"
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                left: '50%',
                                top: '50%',
                                transform: img002 ? 'translate(-50%, -50%)' : 'translate(-200%, -50%)',
                                transition: 'transform 0.6s',
                                objectFit: 'cover'
                            }} />

                    </div>
                </div>
            </div>

            <div className="company1-process-inform" >

                <h2 className="company1-process-inform_title" >{TEXTS.companyProcessTitle}</h2>

                {processes.map((item, index) => (
                    <div className='company1-process-step' key={index} >
                        <div >
                            <p className="company1-process-step_number" >{item.number}</p>
                            <h3 className="company1-process-step_title" ref={item.ref}>{item.title}</h3>
                        </div>
                        <div>
                            <p className="company1-process-step_text">{item.text}</p>
                        </div>
                    </div>
                ))}

            </div>
        </div >
    )
}

export default CompanyProcess;
