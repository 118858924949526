import Header from "../components/Header/Header";
import Presentation from "../components/Presentation/Presentation";
import About from "../components/About/About";
import Industries from "../components/Industries/Industries";
import Portfolio from "../components/Portfolio/Portfolio";
import Services from "../components/Services/Services";
import Footer from "../components/Footer/Footer";
import { withCookiesCheker } from "../HOCs/withCookiesCheker";

import { useSelector, useDispatch } from "react-redux";
import { switchFromPrivacyToHome } from "../store/slices/switchFromPrivacyToHomeSlice";
import { useEffect } from "react";
import { useScrollToId } from "../hooks/useScrollToId";



const Index = () => {

  const state = useSelector(state => state.switchFromPrivacyToHome);
  const dispatch = useDispatch();

  const scrollToFooter = () => {
    let footer = document.getElementById('footer')
    footer.scrollIntoView({ behavior: 'smooth' })
    dispatch(switchFromPrivacyToHome(false));
  }

  useEffect(() => {

    if (state.switchFromPrivacyToHome) {
      setTimeout(() => {
        scrollToFooter();
      }, 1000)
    }
  }, [])

  const scrollToId = useScrollToId();

  return (
    <>
      <Header />
      <Presentation />
      <About />
      <Industries />
      <Portfolio />
      <Services />
      <Footer />
    </>
  )
}

export default withCookiesCheker(Index);