import { useState } from 'react';
import style from './AnotherServicesCard.module.css';



const AnotherServicesCard = ({ card }) => {

    const [hover, setHover] = useState(false);

    return (

        <a
            href={card.href}
            className={style.container}
        >
            <div
                className={style.img__wrapper}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <picture >
                    <source srcSet={card.imgMobile} media="(max-width: 1365px)" />
                    <source srcSet={card.imgLaptop} media="(max-width: 1919px)" />
                    <img src={card.imgDesktop} alt="" />
                </picture>

                {hover && (
                    <div className={style.img__hover} >
                        <p className={style.img__hover_title}>{card.hoverTitle}</p>
                    </div>
                )}
            </div>

            <h3 className={style.title}>{card.title}</h3>
            <p className={style.text}>{card.text}</p>

        </a>
    )
}

export default AnotherServicesCard;