import axios from "axios";
import { API_URL } from "../../const";

const getAllVacancies = async () => {

    const token = localStorage.getItem('token')
    const res = await axios.get(`${API_URL}/api/vacancy`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );;
    return res.data;
}

const getOneVacancy = async (_id) => {

    const token = localStorage.getItem('token')
    const res = await axios.get(`${API_URL}/api/vacancy/` + _id,
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
);;
    return res.data;
}

const createVacancy = async (data) => {

    const token = localStorage.getItem('token')
    const newVacancy = await axios.post(`${API_URL}/api/vacancy`, data,
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
);;
    return newVacancy.data;
}

const updateVacancy = async (data) => {

    const token = localStorage.getItem('token')
    const res = await axios.put(`${API_URL}/api/vacancy`, data,
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
);;
    return res.data;
}

const removeVacancy = async (_id) => {

    const token = localStorage.getItem('token')
    await axios.delete(`${API_URL}/api/vacancy/` + _id,
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
);;
    return _id;
}

const vacanciesService = {
    getAllVacancies,
    getOneVacancy,
    createVacancy,
    updateVacancy,
    removeVacancy
}

export default vacanciesService;