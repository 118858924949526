import './DDTValue.css';
import { TEXTS } from '../../texts';


const DDTValue = () => {



  return (
    <div className="ddt-value">

      <h2 className="ddt-value-title">{TEXTS.DDTValueTitle}</h2>

      <div className="ddt-value-wrapper">

        <div className="ddt-value-card linear-hover">
          <div className="linear-hover-line"></div>
          <div className="ddt-value-card-wrapper">
            <h3 className="ddt-value-card_title">Geographical and<br />cultural proximity</h3>
            <span className="ddt-value-card_number">{TEXTS.number01}</span>
          </div>
          <p className="ddt-value-card_text">{TEXTS.DDTValueText_1}</p>
        </div>

        <div className="ddt-value-card linear-hover">
          <div className="linear-hover-line"></div>
          <div className="ddt-value-card-wrapper">
            <h3 className="ddt-value-card_title">Reduce time spent<br />on recruitment</h3>
            <span className="ddt-value-card_number">{TEXTS.number02}</span>
          </div>
          <p className="ddt-value-card_text">{TEXTS.DDTValueText_2}</p>
        </div>

        <div className="ddt-value-card linear-hover">
          <div className="linear-hover-line"></div>
          <div className="ddt-value-card-wrapper">
            <h3 className="ddt-value-card_title">Be flexible with<br />a team size</h3>
            <span className="ddt-value-card_number">{TEXTS.number03}</span>
          </div>
          <p className="ddt-value-card_text">{TEXTS.DDTValueText_3}</p>
        </div>

        <div className="ddt-value-card linear-hover">
          <div className="linear-hover-line"></div>
          <div className="ddt-value-card-wrapper">
            <h3 className="ddt-value-card_title">85% of the team –<br />senior/middle engineers</h3>
            <span className="ddt-value-card_number">{TEXTS.number04}</span>
          </div>
          <p className="ddt-value-card_text">{TEXTS.DDTValueText_4}</p>
        </div>

        <div className="ddt-value-card-last linear-hover-last">
          <div className="linear-hover-line-last"></div>
          <div className="ddt-value-card-wrapper-last">
            <h3 className="ddt-value-card_title">9.9/10 client<br />satisfaction score</h3>
            <span className="ddt-value-card_number">{TEXTS.number05}</span>
          </div>
          <p className="ddt-value-card_text-last">{TEXTS.DDTValueText_5}</p>
        </div>

      </div>
    </div>
  )
}

export default DDTValue;