import { useEffect } from "react";
import CareersText from "../components/CareersText/CareersText";
import CareersInformation from "../components/CareersInformation/CareersInformation";
import CareersVacancy from "../components/CareersVacancy/CareersVacancy";
import CareersAdvantages from "../components/CareersAdvantages/CareersAdvantages";
import Header from "../components/Header/Header";
import FooterCareers from "../components/FooterCareers/FooterCareers";
import { withCookiesCheker } from "../HOCs/withCookiesCheker";
import { useScrollToId } from "../hooks/useScrollToId";



const Careers = () => {

  useEffect(() => {
    let borderTop = document.getElementsByClassName('accordion-vacancy-buttons');
    if (borderTop[0]) borderTop[0].style.borderTop = 'none';
  }, []);

  const scrollToId = useScrollToId();

  return (
    <>
      <Header />
      <CareersText />
      <CareersInformation />
      <CareersVacancy />
      <CareersAdvantages />
      <FooterCareers />
    </>
  )
}

export default withCookiesCheker(Careers);