import FooterContactForm from '../FooterContactForm/FooterContactForm';
import FooterSocial from '../FooterSocial/FooterSocial';
import FooterPrivacy from '../FooterPrivacy/FooterPrivacy';



const Footer = () => {

    return (
        <footer id='footer'>
            <FooterContactForm />
            <FooterSocial />
            <FooterPrivacy />
        </footer>
    )
}

export default Footer;
