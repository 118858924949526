import style from './OutsourceSuited.module.css';
import { TEXTS } from '../../texts';



const OutsourceSuited = () => {



    return (
        <div className={style.wrapper} >

            <h2 className={style.title} >{TEXTS.outsourceSuitedTitle}</h2>

            <div className={style.container}>

                <div className={style.block1}>
                    <p className={style.block1__title}>{TEXTS.outsourceSuitedBlockTitle_1}</p>
                    <p className={style.block1__text}>{TEXTS.outsourceSuitedBlockText_1}</p>
                </div>

                <div className={style.block2}>
                    <p className={style.block2__title}>{TEXTS.outsourceSuitedBlockTitle_2}</p>
                    <p className={style.block2__text}>{TEXTS.outsourceSuitedBlockText_2}</p>
                </div>

                <div className={style.block3}>
                    <p className={style.block2__title}>{TEXTS.outsourceSuitedBlockTitle_3}</p>
                    <p className={style.block2__text}>{TEXTS.outsourceSuitedBlockText_3}</p>
                </div>

            </div>
        </div>
    )
}

export default OutsourceSuited;