import React, { useEffect, useState, useRef } from 'react';
import style from './ServicesReason.module.css';
import InformationList from '../CompanyPresentation/InformationList/InformationList';



const ServicesReason = () => {

    const textforObserver = useRef();
    const [textInView, setTextInView] = useState();

    useEffect(() => {
        const observerForText = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setTextInView(entry.isIntersecting);

              if (entry.isIntersecting) {
            observerForText.unobserve(textforObserver.current);
          }
        });
        observerForText.observe(textforObserver.current);

    }, []);

    return (
        <div className={style.wrapper} id='arrow-down'>
            <h3 className={style.title} ref={textforObserver}>Reason to work with <span>Nitrix</span></h3>
            <div className={style.informationList}>
                <InformationList inView={textInView} />
            </div>
        </div>
    )
}

export default ServicesReason;