import React from 'react';
import style from './ServicesStack.module.css';
import { TEXTS } from '../../texts';
import SwiftIcon from '../../img/service/technologies/Swift.svg';
import FirebaseIcon from '../../img/service/technologies/Firebase.svg';
import FlutterIcon from '../../img/service/technologies/Flutter.svg';
import ReactNativeIcon from '../../img/service/technologies/ReactNative.svg';
import KotlinIcon from '../../img/service/technologies/Kotlin.svg';
import JetpackIcon from '../../img/service/technologies/Jetpack.svg';
import JavaIcon from '../../img/service/technologies/Java.svg';
import SDKIcon from '../../img/service/technologies/SDK.svg';
import ReactIcon from '../../img/service/technologies/React.svg';
import ReduxIcon from '../../img/service/technologies/Redux.svg';
import MobXIcon from '../../img/service/technologies/MobX.svg';
import NextIcon from '../../img/service/technologies/Next.svg';
import NodeIcon from '../../img/service/technologies/Node.svg';
import ExpressIcon from '../../img/service/technologies/Express.svg';
import MongoIcon from '../../img/service/technologies/Mongo.svg';
import PostgreIcon from '../../img/service/technologies/Postgre.svg';
import JWTIcon from '../../img/service/technologies/JWT.svg';
import BCryptIcon from '../../img/service/technologies/BCrypt.svg';
import SSHIcon from '../../img/service/technologies/SSH.svg';
import SPAIcon from '../../img/service/technologies/SPA.svg';
import SSRIcon from '../../img/service/technologies/SSR.svg';
import UXUIIcon from '../../img/service/technologies/UXUI.svg';
import MobileIcon from '../../img/service/technologies/Mobile.svg';
import WebIcon from '../../img/service/technologies/Web.svg';
import BrandIcon from '../../img/service/technologies/Brand.svg';
import IlustratorIcon from '../../img/service/technologies/Ilustrator.svg';
import QAIcon from '../../img/service/technologies/QA.svg';
import PMIcon from '../../img/service/technologies/PM.svg';




const ServicesStack = () => {

    return (
        <div className={style.wrapper}>

            <h3 className={style.title}>{TEXTS.servicesStackTitle}</h3>
            <p className={style.text}>{TEXTS.servicesStackText}</p>

            <div className={style.container}>

                <div className={style.block}>
                    <h4 className={style.subtitle}>{TEXTS.servicesStackSubtitle_1}</h4>
                    <hr />
                    <div className={style.block__container}>
                        <div>
                            <div className={style.technology}>
                                <h5 className={style.technology__title}>{TEXTS.servicesStackTechnologyTitle_1}</h5>
                                <div className={style.technology__container}>
                                    <img src={SwiftIcon} alt="" />
                                    <img src={FirebaseIcon} alt="" />
                                </div>
                            </div>
                            <div className={style.technology}>
                                <h5 className={style.technology__title}>{TEXTS.servicesStackTechnologyTitle_2}</h5>
                                <div className={style.technology__container}>
                                    <img src={KotlinIcon} alt="" />
                                    <img src={JavaIcon} alt="" />
                                    <img src={JetpackIcon} alt="" />
                                    <img src={SDKIcon} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className={style.technology}>
                            <h5 className={style.technology__title}>{TEXTS.servicesStackTechnologyTitle_3}</h5>
                            <div className={style.technology__container}>
                                <img src={FlutterIcon} alt="" />
                                <img src={ReactNativeIcon} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.block}>
                    <h4 className={style.subtitle}>{TEXTS.servicesStackSubtitle_2}</h4>
                    <hr />
                    <div className={style.block__container}>
                        <div className={style.technology}>
                            <h5 className={style.technology__title}>{TEXTS.servicesStackTechnologyTitle_4}</h5>
                            <div className={style.technology__container}>
                                <img src={ReactIcon} alt="" />
                                <img src={ReduxIcon} alt="" />
                                <img src={MobXIcon} alt="" />
                                <img src={NextIcon} alt="" />
                            </div>
                        </div>
                        <div className={style.technology}>
                            <h5 className={style.technology__title}>{TEXTS.servicesStackTechnologyTitle_5}</h5>
                            <div className={style.technology__container}>
                                <img src={NodeIcon} alt="" />
                                <img src={ExpressIcon} alt="" />
                            </div>
                        </div>
                        <div className={style.technology}>
                            <h5 className={style.technology__title}>{TEXTS.servicesStackTechnologyTitle_6}</h5>
                            <div className={style.technology__container}>
                                <img src={MongoIcon} alt="" />
                                <img src={PostgreIcon} alt="" />
                                <img src={FirebaseIcon} alt="" />
                            </div>
                        </div>
                        <div className={style.technology}>
                            <h5 className={style.technology__title}>{TEXTS.servicesStackTechnologyTitle_7}</h5>
                            <div className={style.technology__container}>
                                <img src={JWTIcon} alt="" />
                                <img src={BCryptIcon} alt="" />
                                <img src={SSHIcon} alt="" />
                                <img src={SPAIcon} alt="" />
                                <img src={SSRIcon} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.block}>
                    <h4 className={style.subtitle}>{TEXTS.servicesStackSubtitle_3}</h4>
                    <hr />
                    <div className={style.block__container}>
                        <div className={style.technology + ' ' + style.technology__design}>
                            <div className={style.technology__container + ' ' + style.technology__container_design}>
                                <img src={UXUIIcon} alt="" />
                                <img src={MobileIcon} alt="" />
                                <img src={WebIcon} alt="" />
                                <img src={BrandIcon} alt="" />
                                <img src={IlustratorIcon} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.block}>
                    <h4 className={style.subtitle}>{TEXTS.servicesStackSubtitle_4}</h4>
                    <hr />
                    <div className={style.block__container}>
                        <div className={style.technology + ' ' + style.technology__specialists}>
                            <div className={style.technology__container}>
                                <img src={QAIcon} alt="" />
                                <img src={PMIcon} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ServicesStack;