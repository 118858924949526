export const timeCloseAlert = 3000;

/* export const API_URL = 'http://localhost:4444';  */
export const API_URL = 'https://nitrix.io'; 



/* export const RECAPTCHA_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' */

export const RECAPTCHA_KEY = '6LdwaOMmAAAAANZ5ZTfoG0b11TFg4PRLjTGSg4sW'
