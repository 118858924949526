import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUser, logout, reset } from "../store/slices/authSlice";



const CheckAuth = ({ children }) => {

    const token = localStorage.getItem('token');

    const { user, isLoading, isError, message } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUser(token));
    }, []);

    useEffect(() => {
        if (!token || isError) {
            dispatch(reset());
        }
    }, [token])

    if (!token || isError) {
        return <Navigate to='/admin_panel/login' />
    }

    return !user ? null : children;
};

export default CheckAuth;