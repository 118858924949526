import React, { useEffect, useState, useRef } from 'react';
import './CompanyPresentation.css';
import { TEXTS } from '../../texts';
import InformationList from './InformationList/InformationList';
import Flashlight from '../Flashlight/Flashlight';
import CompanyFlashlightBlackWhite from '../../img/company/CompanyFlashlightBlack&White.webp';
import CompanyFlashlightMobile from '../../img/company/CompanyFlashlightMobile.webp';
import CompanyFlashlightColor from '../../img/company/CompanyFlashlightColor.webp';
import cursor from '../../img/company/cursor_join_to_us.webp';



const CompanyPresentation = () => {

  const textforObserver = useRef();
  const [textInView, setTextInView] = useState();

  useEffect(() => {
      const observerForText = new IntersectionObserver((entries) => {
          const entry = entries[0];
          setTextInView(entry.isIntersecting);

          if (entry.isIntersecting) {
            observerForText.unobserve(textforObserver.current);
          }
      });
      observerForText.observe(textforObserver.current);

  }, []);

  return (
    <section className="company-presentation" >

      <div className='company-title-wrapper'>
        <h3 className="company-subtitle">{TEXTS.companySubtitle}</h3>
        <h1 className="company-title">{TEXTS.companyTitle}</h1>
      </div>

      <div className='company-flashlight-wrapper'>
        <Flashlight
          imgFront={CompanyFlashlightBlackWhite}
          imgBack={CompanyFlashlightColor}
          imgMobile={CompanyFlashlightMobile}
          imgFrontId='img__company1'
          imgBackId='img__company2'
          loopSize={170}
          cursor={cursor}
          cursorHeight={150}
          cursorWidth={150}
          cursorId='cursor__company'
          objectPosition={'50% 80%'}
          href='/careers'
          newTab={false}
        />
      </div>

      <div id="arrow-down" className="company-information information" >
        <div className="information-wrapper" >
          <h2 className="information_title" ref={textforObserver}>We are N<span className="information_title_blue">IT</span>RIX</h2>
          <div className="information_text-inner">
            <p className="information_text">{TEXTS.companyInformationText_1}</p>
            <p className="information_text">{TEXTS.companyInformationText_2}</p>
            <p className="information_text" ref={textforObserver}>{TEXTS.companyInformationText_3}</p>
          </div>

          <InformationList inView={textInView} />

        </div>
      </div>
    </section>
  )
}

export default CompanyPresentation;
