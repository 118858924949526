import style from './OutsourceAnother.module.css';
import { TEXTS } from '../../texts';
import AnotherServicesCard from '../AnotherServicesCard/AnotherServicesCard';

import OutstaffImgDesktop from '../../img/service/service-outstaff-img.webp';
import OutstaffImgLaptop from '../../img/service/service-outstaff-img-1365.webp';
import OutstaffImgMobile from '../../img/service/service-outstaff-img-768.webp';
import DDTImgDesktop from '../../img/service/service-ddt-img.webp';
import DDTImgLaptop from '../../img/service/service-ddt-img-1365.webp';
import DDTImgMobile from '../../img/service/service-ddt-img-768.webp';



const OutsourceAnother = () => {

    const anotherServices = [
        {
            href: '/services/ddt',
            title: TEXTS.anotherServicesDDTTitle,
            text: TEXTS.anotherServicesDDTText,
            imgDesktop: DDTImgDesktop,
            imgLaptop: DDTImgLaptop,
            imgMobile: DDTImgMobile,
            hoverTitle: TEXTS.anotherServicesDDTHoverTitle,
        },
        {
            href: '/services/outstaff',
            title: TEXTS.anotherServicesOutstaffTitle,
            text: TEXTS.anotherServicesOutstaffText,
            imgDesktop: OutstaffImgDesktop,
            imgLaptop: OutstaffImgLaptop,
            imgMobile: OutstaffImgMobile,
            hoverTitle: TEXTS.anotherServicesOutstaffHoverTitle,
        }]

    return (
        <div className={style.wrapper}>

            <h2 className={style.title}>{TEXTS.anotherServicesTitle}</h2>

            <div className={style.container}>

                {anotherServices.map((card, index) => (
                    <AnotherServicesCard card={card} key={index} />
                ))}

            </div>
        </div>
    )

}

export default OutsourceAnother;