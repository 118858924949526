import './CareersInformation.css';
import { TEXTS } from '../../texts';




const CareersInformation = () => {



    return (
        <div className="careers-information" id="arrow-down">

            <div className="careers-information-wrapper">

                <h2 className="careers-information_title">{TEXTS.careersInformationTitle}</h2>

                <div className="careers-information_texts">
                    <p className="careers-information_text">{TEXTS.careersInformationText_1}</p>
                    <p className="careers-information_text">{TEXTS.careersInformationText_2}</p>
                </div>
            </div>
            
        </div>
    )
}

export default CareersInformation;