import React from 'react';
import './CompanyValue.css';
import { TEXTS } from '../../texts';



const CompanyValue = () => {

  return (
    <div className="company-value value">

      <h2 className="value-title">{TEXTS.companyOurValues}</h2>

      <div className="value-wrapper">

        <div className="value-card linear-hover">
          <div className="linear-hover-line"></div>
          <div className="value-card-wrapper">
            <h3 className="value-card_title">{TEXTS.companyValue_1}</h3>
            <span className="value-card_number">{TEXTS.number01}</span>
          </div>
          <p className="value-card_text">{TEXTS.companyValue_1_description}</p>
        </div>

        <div className="value-card linear-hover">
          <div className="linear-hover-line"></div>
          <div className="value-card-wrapper">
            <h3 className="value-card_title">{TEXTS.companyValue_2}</h3>
            <span className="value-card_number">{TEXTS.number02}</span>
          </div>
          <p className="value-card_text">{TEXTS.companyValue_2_description}</p>
        </div>

        <div className="value-card value-card-tablet linear-hover-last">
          <div className="linear-hover-line-last"></div>
          <div className="value-card-wrapper value-card-wrapper-tablet">
            <h3 className="value-card_title">{TEXTS.companyValue_3}</h3>
            <span className="value-card_number">{TEXTS.number03}</span>
          </div>
          <p className="value-card_text value-card-text-tablet">{TEXTS.companyValue_3_description}</p>
        </div>

      </div>
    </div>
  )
}

export default CompanyValue;