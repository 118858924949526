import './DDTPros.css';
import { TEXTS } from '../../texts';



const DDTPros = () => {



  return (
    <div className="ddt-pros">
      <div className="ddt-pros-wrapper">

        <h2 className="ddt-pros-title">{TEXTS.DDTProsTitle}</h2>
        <h2 className="ddt-pros-title-mobile">{TEXTS.DDTProsTitle}</h2>

        <div className="ddt-pros-card linear-hover">
          <div className="linear-hover-line"></div>
          <div className="ddt-pros-card-wrapper">
            <h3 className="ddt-pros-card_title">{TEXTS.DDTProsCardTitle_1}</h3>
            <span className="ddt-pros-card_number">{TEXTS.number01}</span>
          </div>
          <p className="ddt-pros-card_text">{TEXTS.DDTProsCardText_1}</p>
        </div>

        <div className="ddt-pros-card linear-hover">
          <div className="linear-hover-line"></div>
          <div className="ddt-pros-card-wrapper">
            <h3 className="ddt-pros-card_title">{TEXTS.DDTProsCardTitle_2}</h3>
            <span className="ddt-pros-card_number">{TEXTS.number02}</span>
          </div>
          <p className="ddt-pros-card_text">{TEXTS.DDTProsCardText_2}</p>
        </div>

        <div className="ddt-pros-card linear-hover">
          <div className="linear-hover-line"></div>
          <div className="ddt-pros-card-wrapper">
            <h3 className="ddt-pros-card_title">{TEXTS.DDTProsCardTitle_3}</h3>
            <span className="ddt-pros-card_number">{TEXTS.number03}</span>
          </div>
          <p className="ddt-pros-card_text">{TEXTS.DDTProsCardText_3}</p>
        </div>

        <div className="ddt-pros-card-last linear-hover-last">
          <div className="linear-hover-line-last"></div>
          <div className="ddt-pros-card-wrapper-last">
            <h3 className="ddt-pros-card_title">{TEXTS.DDTProsCardTitle_4}</h3>
            <span className="ddt-pros-card_number">{TEXTS.number04}</span>
          </div>
          <p className="ddt-pros-card_text-last">{TEXTS.DDTProsCardText_4}</p>
        </div>

      </div>
    </div>
  )
}

export default DDTPros;