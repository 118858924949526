import React, { useState, useRef } from 'react';
import './OutsourceServicess.css';
import { TEXTS } from '../../texts';
import IndustriesCard from '../Industries/IndustriesCard/IndustriesCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Keyboard } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
import WebAppServiceCover from '../../img/service/outsource/WebAppServiceCover.webp';
import MobileAppServiceCover from '../../img/service/outsource/MobileAppServiceCover.webp';
import EndToEndCover from '../../img/service/outsource/EndToEndCover.webp';
import DevTeamsCover from '../../img/service/outsource/DevTeamsCover.webp';
import QualityCover from '../../img/service/outsource/QualityCover.webp';



const OutsourceServicess = () => {

  const swiperRef = useRef(null);

  const [statusCard1, setStatusCard1] = useState('open');
  const [statusCard2, setStatusCard2] = useState(null);
  const [statusCard3, setStatusCard3] = useState(null);
  const [statusCard4, setStatusCard4] = useState(null);
  const [statusCard5, setStatusCard5] = useState(null);

  const [counter, setCounter] = useState('01');

  const toSlide = (index) => {
    if (swiperRef && swiperRef.current) {
      swiperRef.current.swiper.slideTo(index, 500, false);
    }
  }


  const outsourceServicesCards = [
    {
      title: TEXTS.outsourceServicesCardTitle_1,
      number: TEXTS.number01,
      text: TEXTS.outsourceServicesCardText_1,
      cover: WebAppServiceCover,
      statusCard: statusCard1
    },
    {
      title: TEXTS.outsourceServicesCardTitle_2,
      number: TEXTS.number02,
      text: TEXTS.outsourceServicesCardText_2,
      cover: MobileAppServiceCover,
      statusCard: statusCard2
    },
    {
      title: TEXTS.outsourceServicesCardTitle_3,
      number: TEXTS.number03,
      text: TEXTS.outsourceServicesCardText_3,
      cover: EndToEndCover,
      statusCard: statusCard3
    },
    {
      title: TEXTS.outsourceServicesCardTitle_4,
      number: TEXTS.number04,
      text: TEXTS.outsourceServicesCardText_4,
      cover: DevTeamsCover,
      statusCard: statusCard4
    },
    {
      title: TEXTS.outsourceServicesCardTitle_5,
      number: TEXTS.number05,
      text: TEXTS.outsourceServicesCardText_5,
      cover: QualityCover,
      statusCard: statusCard5
    }
  ]

  return (
    <div className="outsource-servicess" >



      <div className="outsource-servicess2">

        <div className="outsource-servicess-counter-wrapper">
          <p className='outsource-servicess-counter'>{counter}</p>/<p className='outsource-servicess-counter-total'>05</p>
        </div>


        <div className="outsource-servicess-wrapper" >

          <div className="outsource-servicess-card outsource-servicess-card-info" style={{ borderTop: 'none', backgroundColor: '#FDFDFD' }}>
            <div className="outsource-servicess-content">
              <h3 className="outsource-servicess-title">{TEXTS.outsourceServicesTitle}</h3>

            </div>
          </div>

          <Swiper
            ref={swiperRef}
            modules={[Mousewheel, Keyboard]}

            onSlideChange={(slide) => {

              if (slide.realIndex === 0) {
                setCounter('01')

                setStatusCard1('open')
                setTimeout(() => {
                  slide.params.mousewheel.releaseOnEdges = true;
                }, 800)

                let content = document.querySelector('.outsource-servicess-content')
                content.classList.remove('outsource-servicess-content-animation')

                if (statusCard2 === 'open') setStatusCard2('closed')
                if (statusCard3 === 'open') setStatusCard3('closed')
              }



              if (slide.realIndex === 1) {
                setCounter('02')

                setStatusCard2('open')
                slide.params.mousewheel.releaseOnEdges = false;

                let content = document.querySelector('.outsource-servicess-content')
                content.classList.add('outsource-servicess-content-animation')

                if (statusCard1 === 'open') setStatusCard1('closed')
                if (statusCard3 === 'open') setStatusCard3('closed')
                if (statusCard4 === 'open') setStatusCard4('closed')
              }

              if (slide.realIndex === 2) {
                setCounter('03')

                setStatusCard3('open')
                slide.params.mousewheel.releaseOnEdges = false;

                if (statusCard2 === 'open') setStatusCard2('closed')
                if (statusCard4 === 'open') setStatusCard4('closed')
                if (statusCard1 === 'open') setStatusCard1('closed')
                if (statusCard5 === 'open') setStatusCard5('closed')
              }

              if (slide.realIndex === 3) {
                setCounter('04')

                setStatusCard4('open')
                slide.params.mousewheel.releaseOnEdges = false;

                if (statusCard3 === 'open') setStatusCard3('closed')
                if (statusCard5 === 'open') setStatusCard5('closed')
                if (statusCard2 === 'open') setStatusCard2('closed')
              }

              if (slide.realIndex === 4) {
                setCounter('05')

                setStatusCard5('open')
                slide.params.mousewheel.releaseOnEdges = false;

                if (statusCard4 === 'open') setStatusCard4('closed')
                if (statusCard3 === 'open') setStatusCard3('closed')
              }



              if (slide.realIndex === 5) {
                setCounter('06')

                setTimeout(() => {
                  slide.params.mousewheel.releaseOnEdges = true;
                }, 800)

                if (statusCard5 === 'open') setStatusCard5('closed')
                if (statusCard4 === 'open') setStatusCard4('closed')
              }

            }}



            normalizeSlideIndex={true}
            mousewheel={{
              /*    sensitivity: 1, */
              forceToAxis: true
            }}
            simulateTouch={true}
            touchRatio={0.3}
            grabCursor={true}
            touchMoveStopPropagation={true}
            keyboard={{
              enabled: true,
              onlyInViewport: true,
              pageUpDown: true
            }}
            breakpoints={{
              768: {
                slidesPerView: 4,
                spaceBetween: 0
              },
              1366: {
                slidesPerView: 5,
                spaceBetween: 0
              },
              1920: {
                slidesPerView: 6,
                spaceBetween: 0
              }
            }}
            className='slider-services'
          >


            <SwiperSlide style={{ height: 'auto' }}></SwiperSlide>

            {outsourceServicesCards.map((card, index) => (
              <SwiperSlide key={index} id={card.title} onClick={() => toSlide(index)}>
                <IndustriesCard
                  title={card.title}
                  number={card.number}
                  text={card.text}
                  cover={card.cover}
                  statusCard={card.statusCard}
                />
              </SwiperSlide>
            ))}

            <SwiperSlide></SwiperSlide>
            <SwiperSlide></SwiperSlide>
            <SwiperSlide className='outsource-servicess-card-768none'></SwiperSlide>
            <SwiperSlide className='outsource-servicess-card-1366none'></SwiperSlide>

          </Swiper>


        </div>



        <div className="outsource-servicess-wrapper-mobile" >

          <div className="outsource-servicess-content">
            <h3 className="outsource-servicess-title">{TEXTS.outsourceServicesTitle}</h3>

          </div>

          {outsourceServicesCards.map((card, index) => (
            <IndustriesCard
              title={card.title}
              number={card.number}
              text={card.text}
              cover={card.cover}
              key={index}
            />
          ))}

        </div>

      </div>
    </div >
  )
}

export default OutsourceServicess;