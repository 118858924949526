import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    industries: ''
}

const industriesSlice = createSlice({
    name: 'industries',
    initialState,
    reducers: {
        setIndustry(state, action) {
            let newState = state.industries
            newState = action.payload
            state.industries = newState
        }
    }

})

export const { setIndustry } = industriesSlice.actions;

export const industriesReducer = industriesSlice.reducer;