import React from 'react';
import './ServicesFaq.css';

import FAQAccordion from '../FAQAccordion/FAQAccordion';
import { TEXTS } from '../../texts';



const ServicesFaq = () => {

    const accordions = [
        {
            question: TEXTS.servicesFaqQuestion_1,
            text: TEXTS.servicesFaqText_1,
        },
        {
            question: TEXTS.servicesFaqQuestion_2,
            text: TEXTS.servicesFaqText_2,
        },
        {
            question: TEXTS.servicesFaqQuestion_3,
            text: TEXTS.servicesFaqText_3,
        }
    ]

    return (
        <div className="services-faq">
            <div className="services-faq-wrapper">

                <h2 className="services-faq-title">{TEXTS.FAQ}</h2>

                {accordions.map((accordion, index) => (
                    <FAQAccordion
                        key={index}
                        question={accordion.question}
                        text={accordion.text}
                    />
                ))}

            </div>
        </div>
    )
}

export default ServicesFaq;