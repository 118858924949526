import React, { useState } from 'react';
import './FAQAccordion.css';



const FAQAccordion = ({ question, text, arrItems }) => {

    const [ariaExpanded, setAriaExpanded] = useState(false);
    const [newClass, setNewClass] = useState('accordion-nonclass');
    const [classArrow1, setClassArror1] = useState(null);



    const handleAccordion = () => {

        setAriaExpanded(!ariaExpanded);

        if (ariaExpanded) {
            setClassArror1('rotate-180-cw-return');
            setNewClass('accordion-nonclass');
        } else {
            setClassArror1('rotate-180-cw');
            setNewClass('accordion-class');
        }
    }



    return (

        <div className="accordion-faq-content">

            <button className={`buttons ${newClass}`}
                id="accordion-button-1" aria-expanded={ariaExpanded}
                aria-hidden="true"
                onClick={handleAccordion}>

                <div className="accordion-title">
                    {question}
                    <svg className={`new-faq-arrow ${classArrow1}`} width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L8 9.5L15 1" />
                    </svg>
                </div>

            </button>

            <div className="accordion-content">
                <p className="accordion-faq-text">{text}</p>

                {arrItems && (
                    <ul className="accordion-faq-list">
                        {arrItems.map((item, index) => (
                            <li className="accordion-faq-item" key={index} >
                                <p>
                                    {item.title && <span className="accordion-faq-item-bold" >{item.title}</span>}   {item.text}
                                </p>
                            </li>
                        ))}
                    </ul>
                )}

            </div>
        </div>
    )
}

export default FAQAccordion;