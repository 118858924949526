import React, { useState, useEffect } from 'react';
import './Services.css';
import { TEXTS } from '../../texts';

import ServicesPngImg from '../../img/services-img.webp';
import Services2PngImg from '../../img/services-img-2.webp';
import Services3PngImg from '../../img/services-img-3.webp';
import ServicesPngImg1920 from '../../img/services-img-1920.webp';
import Services2PngImg1920 from '../../img/services-img-2-1920.webp';
import Services3PngImg1920 from '../../img/services-img-3-1920.webp';
import ServicesPngImg1366 from '../../img/services-img-1366.webp';
import Services2PngImg1366 from '../../img/services-img-2-1366.webp';
import Services3PngImg1366 from '../../img/services-img-3-1366.webp';
import ServicesPngImg768 from '../../img/services-img-768.webp';
import Services2PngImg768 from '../../img/services-img-2-768.webp';
import Services3PngImg768 from '../../img/services-img-3-768.webp';



const Services = () => {

    const [outsource, setOutsource] = useState(true);
    const [outstaff, setOutstaff] = useState(false);
    const [ddt, setDdt] = useState(false);

    const [index, setIndex] = useState(0);

    const outsourceHandler = () => {
        setOutsource(true);
        setOutstaff(false);
        setDdt(false);
        setIndex(0);
    }

    const outstaffHandler = () => {
        setOutsource(false);
        setOutstaff(true);
        setDdt(false);
        setIndex(1);
    }

    const ddtHandler = () => {
        setOutsource(false);
        setOutstaff(false);
        setDdt(true);
        setIndex(2);
    }



    useEffect(() => {
        let line = document.querySelector('.services-active-line');
        let width = document.querySelector('.services-sutitle_active').clientWidth;
        line.style.width = `${width}px`
    }, [])

    const lineAnimation = e => {
        document.querySelectorAll('.services-sutitle').forEach(e => {
            e.classList.remove('services-sutitle_active');
        });
        e.target.classList.add('services-sutitle_active');
        let mainWidth = document.querySelector('.services-wrapper').clientWidth;
        let line = document.querySelector('.services-active-line');
        let elemWidth = e.target.clientWidth;
        let perc = mainWidth / 100;
        let res = elemWidth / perc;
        line.style.width = `${res}%`;
        line.style.left = `${e.target.offsetLeft}px`
    }



    return (
        <div className="services">
            <div className="services-content">

                <h3 className="services-title">{TEXTS.servicesTitleOnHome}</h3>

                <div className="services-wrapper">
                    <span ><p className="services-sutitle services-sutitle_active" onClick={(e) => { outsourceHandler(); lineAnimation(e); }}>{TEXTS.OUTSOURCE}</p></span>
                    <span ><p className="services-sutitle" onClick={(e) => { outstaffHandler(); lineAnimation(e); }}>{TEXTS.OUTSTAFF}</p></span>
                    <span ><p className="services-sutitle" onClick={(e) => { ddtHandler(); lineAnimation(e); }}>{TEXTS.DDT}</p></span>
                    <span className='services-active-line'></span>
                </div>


                {outsource && (
                    <>
                        <p className="services-text">{TEXTS.outsourceDiscriptionOnHome}</p>
                        <a className="services-arrow" href='/services/outsource'>
                            {TEXTS.learnMore}
                            <svg className="services-arrow-svg" width="40" height="15" viewBox="0 0 40 16" fill="none" style={{ marginLeft: '10px' }}>
                                <path fillRule="evenodd" clipRule="evenodd" d="M33.5055 0.798304L39.7143 7.27983C40.0952 7.67757 40.0952 8.32243 39.7143 8.72017L33.5055 15.2017C33.1245 15.5994 32.5068 15.5994 32.1258 15.2017C31.7448 14.804 31.7448 14.1591 32.1258 13.7614L36.6691 9.01847H0L0 6.98153H36.6691L32.1258 2.23864C31.7448 1.8409 31.7448 1.19604 32.1258 0.798304C32.5068 0.400565 33.1245 0.400565 33.5055 0.798304Z" fill="#28C0E2" />
                            </svg>

                        </a>
                    </>
                )}
                {outstaff && (
                    <>
                        <p className="services-text">{TEXTS.outstaffDiscriptionOnHome}</p>
                        <a className="services-arrow" href="/services/outstaff">
                            {TEXTS.learnMore}
                            <svg className="services-arrow-svg" width="40" height="15" viewBox="0 0 40 16" fill="none" style={{ marginLeft: '10px' }}>
                                <path fillRule="evenodd" clipRule="evenodd" d="M33.5055 0.798304L39.7143 7.27983C40.0952 7.67757 40.0952 8.32243 39.7143 8.72017L33.5055 15.2017C33.1245 15.5994 32.5068 15.5994 32.1258 15.2017C31.7448 14.804 31.7448 14.1591 32.1258 13.7614L36.6691 9.01847H0L0 6.98153H36.6691L32.1258 2.23864C31.7448 1.8409 31.7448 1.19604 32.1258 0.798304C32.5068 0.400565 33.1245 0.400565 33.5055 0.798304Z" fill="#28C0E2" />
                            </svg>
                        </a>
                    </>
                )}
                {ddt && (
                    <>
                        <p className="services-text">{TEXTS.ddtDiscriptionOnHome}</p>
                        <a className="services-arrow" href="/services/ddt">
                            {TEXTS.learnMore}
                            <svg className="services-arrow-svg" width="43" height="15" viewBox="0 0 40 16" fill="none" style={{ marginLeft: '8px' }}>
                                <path fillRule="evenodd" clipRule="evenodd" d="M33.5055 0.798304L39.7143 7.27983C40.0952 7.67757 40.0952 8.32243 39.7143 8.72017L33.5055 15.2017C33.1245 15.5994 32.5068 15.5994 32.1258 15.2017C31.7448 14.804 31.7448 14.1591 32.1258 13.7614L36.6691 9.01847H0L0 6.98153H36.6691L32.1258 2.23864C31.7448 1.8409 31.7448 1.19604 32.1258 0.798304C32.5068 0.400565 33.1245 0.400565 33.5055 0.798304Z" fill="#28C0E2" />
                            </svg>
                        </a>
                    </>
                )}
            </div>

            <div className="slideshow">
                <div className="slideshowSlider"
                    style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }} >

                    <picture>
                        <source media="(max-width: 767px)" srcSet={ServicesPngImg768} />
                        <source media="(max-width: 1365px)" srcSet={ServicesPngImg1366} />
                        <source media="(max-width: 1919px)" srcSet={ServicesPngImg1920} />
                        <img src={ServicesPngImg} alt="outsource" className='slideshow_slide' />
                    </picture>

                    <picture>
                        <source media="(max-width: 767px)" srcSet={Services2PngImg768} />
                        <source media="(max-width: 1365px)" srcSet={Services2PngImg1366} />
                        <source media="(max-width: 1919px)" srcSet={Services2PngImg1920} />
                        <img src={Services2PngImg} alt="outstaff" className='slideshow_slide' />
                    </picture>

                    <picture>
                        <source media="(max-width: 767px)" srcSet={Services3PngImg768} />
                        <source media="(max-width: 1365px)" srcSet={Services3PngImg1366} />
                        <source media="(max-width: 1919px)" srcSet={Services3PngImg1920} />
                        <img src={Services3PngImg} alt="ddt" className='slideshow_slide' />
                    </picture>

                </div>
            </div>

        </div>
    )
}

export default Services;