import './OutstaffComparison.css';
import { TEXTS } from '../../texts';
import OutstaffSchemeSvgImg from '../../img/service/outstaff-scheme.svg';
import OutstaffScheme768SvgImg from '../../img/service/outstaff-scheme-768.svg';




const OutstaffComparison = () => {



    return (
        <div className="outstaff-comparison">
            <div className="outstaff-comparison-wrapper">
                <h2 className="outstaff-comparison-title">{TEXTS.outstaffComparisonTitle}</h2>
                <p className="outstaff-comparison-text outstaff-comparison-text-tablet1">{TEXTS.outstaffComparisonText_1}</p>
                <p className="outstaff-comparison-text outstaff-comparison-text-tablet2">{TEXTS.outstaffComparisonText_2}</p>
            </div>
            <div className="outstaff-comparison-scheme">
                <picture>
                    <source media="(max-width: 767px)" srcSet="" />
                    <source media="(max-width: 1365px)" srcSet={OutstaffScheme768SvgImg} />
                    <source media="(max-width: 1919px)" srcSet="" />
                    <img src={OutstaffSchemeSvgImg} alt="OUTSTAFF SCHEME" />
                </picture>

            </div>
            <div className="outstaff-difference">
                <h3 className="outstaff-difference-title">{TEXTS.outstaffDifferenceTitle_1}</h3>
                <p className="outstaff-difference-text">{TEXTS.outstaffDifferenceText_1}</p>
                <p className="outstaff-difference-text">{TEXTS.outstaffDifferenceText_2}</p>
                <p className="outstaff-difference-text">{TEXTS.outstaffDifferenceText_3}</p>
            </div>
            <div className="outstaff-difference1">
                <h3 className="outstaff-difference-title">{TEXTS.outstaffDifferenceTitle_2}</h3>
                <p className="outstaff-difference-text">{TEXTS.outstaffDifferenceText_4}</p>
                <p className="outstaff-difference-text">{TEXTS.outstaffDifferenceText_5}</p>
                <p className="outstaff-difference-text">{TEXTS.outstaffDifferenceText_6}</p>
                <p className="outstaff-difference-text">{TEXTS.outstaffDifferenceText_7}</p>
            </div>
        </div>
    )
}

export default OutstaffComparison;