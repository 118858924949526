import { useEffect, useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Index from "./pages/Index";
import Company from './pages/Company';
import Cases from './pages/Cases';
import Services from './pages/Services';
import Careers from './pages/Careers';
import ServiceDDT from './pages/ServiceDDT';
import ServiceOutsource from './pages/ServiceOutsource';
import ServiceOutstaff from './pages/ServiceOutstaff';

import Cookies from './components/Cookies/Cookies';
import Preloader from './components/Preloader/Preloader';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CheckAuth from './utils/CheckAuth';
import CheckRoles from './utils/CheckRoles';

import AdminReg from './pages/AdminPanel/AdminReg';



const CaseStartup = lazy(() => import('./pages/CaseStartup'));
const CaseAquaPal = lazy(() => import('./pages/CaseAquaPal'));
const CaseJoyn = lazy(() => import('./pages/CaseJoyn'));
const CaseSaqure = lazy(() => import('./pages/CaseSaqure'));
const CaseZise = lazy(() => import('./pages/CaseZise'));
const CaseZiksen = lazy(() => import('./pages/CaseZiksen'));
const CaseRainbow = lazy(() => import('./pages/CaseRainbow'));
const CaseHalves = lazy(() => import('./pages/CaseHalves'));
const CaseTailors = lazy(() => import('./pages/CaseTailors'));
const CaseIsrael = lazy(() => import('./pages/CaseIsrael'));

const Privacy = lazy(() => import('./pages/Privacy'));
const DebertsPrivacy = lazy(() => import('./pages/DebertsPrivacy'));
const AquaPalPrivacy = lazy(() => import('./pages/AquaPalPrivacy'));
const Page404 = lazy(() => import('./pages/404'));

const AdminPanelHome = lazy(() => import('./pages/AdminPanel/AdminPanelHome/AdminPanelHome'));
const AdminPanelLogin = lazy(() => import('./pages/AdminPanel/AdminPanelLogin/AdminPanelLogin'));
const AdminPanelVacancies = lazy(() => import('./pages/AdminPanel/AdminPanelVacancies/AdminPanelVacancies'));
const AdminPanelVacancy = lazy(() => import('./pages/AdminPanel/AdminPanelVacancy/AdminPanelVacancy'));
const AdminPanelArticles = lazy(() => import('./pages/AdminPanel/AdminPanelArticles/AdminPanelArticles'));
const AdminPanelUsers = lazy(() => import('./pages/AdminPanel/AdminPanelUsers/AdminPanelUsers'));


const App = () => {

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true)
      sessionStorage.setItem('preloader', 'off')
    }, 3500);
  }, []);

  return (
    <>
      <div className='grid_wrapper'>

        <Router>
          <Suspense fallback={<div></div>}>

            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/company" element={<Company />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/services" element={<Services />} />
              <Route path="/services/ddt/" element={<ServiceDDT />} />
              <Route path="/services/outstaff" element={<ServiceOutstaff />} />
              <Route path="/services/outsource" element={<ServiceOutsource />} />
              <Route path="/cases" element={<Cases />} />
              <Route path="/cases/halves" element={<CaseHalves />} />
              <Route path="/cases/ziksen" element={<CaseZiksen />} />
              <Route path="/cases/startup-show" element={<CaseStartup />} />
              <Route path="/cases/israel-tv" element={<CaseIsrael />} />
              <Route path="/cases/rainbow" element={<CaseRainbow />} />
              <Route path="/cases/saqure" element={<CaseSaqure />} />
              <Route path="/cases/tailors" element={<CaseTailors />} />
              <Route path="/cases/aqua-pal" element={<CaseAquaPal />} />
              <Route path="/cases/joyn" element={<CaseJoyn />} />
              <Route path="/cases/zise" element={<CaseZise />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/deberts_note/privacy_policy.html" element={<DebertsPrivacy />} />
              <Route path="/cases/aqua-pal/privacy-policy" element={<AquaPalPrivacy />} />


              <Route path='/admin_panel/' element={<CheckAuth><AdminPanelHome /></CheckAuth>} />
              <Route path='/admin_panel/login' element={<AdminPanelLogin />} />
              
              <Route path='/admin_panel/vacancies' element={<CheckAuth><AdminPanelVacancies /></CheckAuth>} />
              <Route path='/admin_panel/vacancy' element={<CheckAuth><AdminPanelVacancy /></CheckAuth>} />
              <Route path='/admin_panel/vacancy/:id/edit' element={<CheckAuth><AdminPanelVacancy /></CheckAuth>} />
              <Route path='/admin_panel/users' element={
                <CheckAuth>
                  <CheckRoles>
                    <AdminPanelUsers />
                  </CheckRoles>
                </CheckAuth>
              } />
              <Route path='/admin_panel/articles' element={<CheckAuth><AdminPanelArticles /></CheckAuth>} />


              <Route path="*" element={<Page404 />} />

            </Routes>

            {sessionStorage.getItem('preloader') == 'off' || isLoading ? null : <Preloader />}
            <Cookies />
            <ToastContainer />

          </Suspense>
        </Router>
      </div>
    </>
  );
}

export default App;
