import style from './OutstaffSuited.module.css';
import { TEXTS } from '../../texts';



const OutstaffSuited = () => {


    return (
        <div className={style.wrapper} >

            <h2 className={style.title} >{TEXTS.outstaffSuitedTitle}</h2>

            <div className={style.container}>

                <div className={style.block1}>
                    <p className={style.block1__title}>{TEXTS.outstaffSuitedBlockTitle_1}</p>
                    <p className={style.block1__text}>{TEXTS.outstaffSuitedBlockText_1}</p>
                </div>

                <div className={style.block2}>
                    <p className={style.block1__title}>{TEXTS.outstaffSuitedBlockTitle_2}</p>
                    <p className={style.block1__text}>{TEXTS.outstaffSuitedBlockText_2}</p>
                </div>

                <div className={style.block3}>
                    <p className={style.block3__title}>{TEXTS.outstaffSuitedBlockTitle_3}</p>
                    <p className={style.block3__text}>{TEXTS.outstaffSuitedBlockText_3}</p>
                </div>

            </div>
        </div>
    )
}

export default OutstaffSuited;