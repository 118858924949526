import DDTText from "../components/DDTText/DDTText";
import DDTInformation from "../components/DDTInformation/DDTInformation";
import DDTValue from "../components/DDTValue/DDTValue";
import DDTProcess from "../components/DDTProcess/DDTProcess";
import DDTPhoto from "../components/DDTPhoto/DDTPhoto";
import DDTPros from "../components/DDTPros/DDTPros";
import DDTFaq from "../components/DDTFaq/DDTFaq";
import DDTAnother from "../components/DDTAnother/DDTAnother";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { withCookiesCheker } from "../HOCs/withCookiesCheker";
import { useScrollToId } from "../hooks/useScrollToId";



const ServiceDDT = () => {

    const scrollToId = useScrollToId();

    return (
        <>
            <Header />
            <DDTText />
            <DDTInformation />
            <DDTValue />
            <DDTProcess />
            <DDTPhoto />
            <DDTPros />
            <DDTFaq />
            <DDTAnother />
            <Footer />
        </>
    )
}

export default withCookiesCheker(ServiceDDT);