import style from './ThankYou.module.css';



const ThankYou = () => {

  return (
    <div className={style.wrapper}>
      <div style={{ textAlign: 'center' }}>
        <p className={style.title}>Thank You!</p>
        <p className={style.text}>Our manager will contact you shortly</p>
      </div>
    </div>
  )
}

export default ThankYou;