import { configureStore } from '@reduxjs/toolkit';
import { cookiesReducer } from './slices/cookies';
import { industriesReducer } from './slices/industries';
import { authReducer } from './slices/authSlice';
import { usersReducer } from './slices/usersSlice';
import { vacanciesReducer } from './slices/vacanciesSlice';
import { switchFromPrivacyToHomeReducer } from './slices/switchFromPrivacyToHomeSlice';



export const store = configureStore({
    reducer: {
        cookies: cookiesReducer,
        industries: industriesReducer,
        auth: authReducer,
        users: usersReducer,
        vacancies: vacanciesReducer,
        switchFromPrivacyToHome: switchFromPrivacyToHomeReducer
    }
})