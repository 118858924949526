import React, { useState, useEffect } from 'react';
import style from './Flashlight.module.css';
import { getBrowser } from '../../utils/getBrowser';



const Flashlight = ({
    imgFront,
    imgBack,
    imgFrontId,
    imgBackId,
    imgMobile,
    loopSize,
    cursor,
    cursorId,
    cursorHeight,
    cursorWidth,
    objectPosition = '50% 50%',
    mobileObjectPosition = '50% 50%',
    href,
    newTab
}) => {

    const [resX, setResX] = useState();
    const [resY, setResY] = useState();

    const [browserSafari, setBrowserSafari] = useState(false);

    useEffect(() => {
        let browser = getBrowser();
        setBrowserSafari(browser.isSafari);
        if (browserSafari) stylesForBrowserSafari()
    }, [browserSafari]);

    const loopAnimation = (mouse, block) => {

        const loop = document.querySelector(`.${imgBackId}`);
        const cursor = document.querySelector(`.${cursorId}`);

        let x = mouse.pageX;
        let y = mouse.pageY;
        let percW = block.offsetWidth / 100;
        let percH = block.offsetHeight / 100;
        let resW = x - block.offsetLeft;
        let resH = y - block.offsetTop;
        setResX(resW / percW);
        setResY(resH / percH);

        loop.classList.add(`${style.img__back_active}`);
        loop.style.clipPath = `circle(${loopSize}px at ${resX}% ${resY}%)`;
        loop.style.setProperty('--x', resX + "%");
        loop.style.setProperty('--y', resY + "%");
        setTimeout(() => {
            loop.style.transition = '.0s ease';
        }, 100)

        cursor.style.top = `${resY}%`;
        cursor.style.left = `${resX}%`;
        cursor.style.transition = '.0s ease';
        cursor.style.width = `${cursorWidth}px`;
        cursor.style.height = `${cursorHeight}px`;
        cursor.classList.add(`${style.cursor_active}`);
    }

    const animationStart = () => {

        if (!browserSafari) {
            const loop = document.querySelector(`.${imgBackId}`);
            loop.style.visibility = 'visible';
            const cursor = document.querySelector(`.${cursorId}`);
            loop.style.setProperty('--loopSize', loopSize + "px");
            cursor.style.setProperty('--cursorHeight', cursorHeight + "px");
            cursor.style.setProperty('--cursorWidth', cursorWidth + "px");

            cursor.style.transition = '.0s ease';
            loop.classList.add(`${style.loop__start}`);
            cursor.classList.add(`${style.cursor__start}`);

            setTimeout(() => {
                loop.classList.remove(`${style.loop__start}`);
                cursor.classList.remove(`${style.cursor__start}`);
            }, 500);
        }
    }

    const animationEnd = () => {
        const loop = document.querySelector(`.${imgBackId}`);
        const cursor = document.querySelector(`.${cursorId}`);
        /* cursor.style.transition = 'ease .2s'; */
        cursor.style.width = `0px`;
        cursor.style.height = `0px`;

        loop.style.clipPath = `circle(0px at ${resX}% ${resY}%)`;
    }

    const stylesForBrowserSafari = () => {
        const loop = document.querySelector(`.${imgBackId}`);
        loop.style.visibility = 'visible';
        loop.style.transition = '.0s ease';
    }

    return (
        <>
            <div className={style.wrapper}
                onMouseMove={(e) => loopAnimation(e, e.target.parentNode)}
                onMouseEnter={animationStart}
                onMouseLeave={() => animationEnd()}
                onClick={() => {
                    if (href) {
                        if (newTab) {
                            window.open(href, newTab)
                        } else {
                            window.location.href = href
                        }
                    }
                }}
            >

                <img className={style.img__back + ' ' + imgBackId} style={{ objectPosition: objectPosition }} src={imgBack} alt="" />
                <img className={style.img__front + ' ' + imgFrontId} style={{ objectPosition: objectPosition }} src={imgFront} alt="" />

                <img src={cursor} alt="" className={style.cursor + ' ' + cursorId} />
            </div>

            <img
                onClick={() => {
                    if (href) {
                        if (newTab) {
                            window.open(href, newTab)
                        } else {
                            window.location.href = href
                        }
                    }
                }}
                className={style.img__mobile}
                style={{ objectPosition: mobileObjectPosition }}
                src={imgMobile}
                alt="nitrix"
            />
        </>
    )
}

export default Flashlight;