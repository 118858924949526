import style from './DDTAnother.module.css';
import { TEXTS } from '../../texts';
import AnotherServicesCard from '../AnotherServicesCard/AnotherServicesCard';

import OutstaffImgDesktop from '../../img/service/service-outstaff-img.webp';
import OutstaffImgLaptop from '../../img/service/service-outstaff-img-1365.webp';
import OutstaffImgMobile from '../../img/service/service-outstaff-img-768.webp';
import OutsourceImgDesktop from '../../img/service/service-outsource-img.webp';
import OutsourceImgLaptop from '../../img/service/service-outsource-img-1365.webp';
import OutsourceImgMobile from '../../img/service/service-outsource-img-768.webp';




const DDTAnother = () => {

  const anotherServices = [
    {
      href: '/services/outsource',
      title: TEXTS.anotherServicesOutsourceTitle,
      text: TEXTS.anotherServicesOutsourceText,
      imgDesktop: OutsourceImgDesktop,
      imgLaptop: OutsourceImgLaptop,
      imgMobile: OutsourceImgMobile,
      hoverTitle: TEXTS.anotherServicesOutsourceHoverTitle
    },
    {
      href: '/services/outstaff',
      title: TEXTS.anotherServicesOutstaffTitle,
      text: TEXTS.anotherServicesOutstaffText,
      imgDesktop: OutstaffImgDesktop,
      imgLaptop: OutstaffImgLaptop,
      imgMobile: OutstaffImgMobile,
      hoverTitle: TEXTS.anotherServicesOutstaffHoverTitle
    }]

  return (
    <div className={style.wrapper}>

      <h2 className={style.title}>{TEXTS.anotherServicesTitle}</h2>

      <div className={style.container}>

        {anotherServices.map((card, index) => (
          <AnotherServicesCard card={card} key={index} />
        ))}

      </div>
    </div>
  )
}

export default DDTAnother;