import { useState, useEffect, useRef } from 'react';
import './DDTProcess.css';
import { TEXTS } from '../../texts';
import DDTProcessPngImg1 from '../../img/service/DDTProcessPngImg1.webp';
import DDTProcessPngImg2 from '../../img/service/DDTProcessPngImg2.webp';



const DDTProcess = () => {

  const step1 = useRef();
  const step5 = useRef();


  const [img001, setImg001] = useState(true);
  const [img002, setImg002] = useState(false);

  const [step1IsVisible, setStep1IsVisible] = useState();
  const [step5IsVisible, setStep5IsVisible] = useState();

  useEffect(() => {
    const observerForStep1 = new IntersectionObserver((entries) => {
      const entry = entries[0]
      setStep1IsVisible(entry.isIntersecting)
    })
    observerForStep1.observe(step1.current)

    const observerForStep5 = new IntersectionObserver((entries) => {
      const entry = entries[0]
      setStep5IsVisible(entry.isIntersecting)
    })
    observerForStep5.observe(step5.current)


  }, [])

  useEffect(() => {

    if (window.innerWidth > 1366) {
      if (step1IsVisible == true) {
        setImg002(false)
        setImg001(true)
      }
      if (step1IsVisible == false && step5IsVisible == true) {
        setImg002(true)
        setImg001(false)
      }
    }

  }, [step1IsVisible, step5IsVisible])


  const processes = [
    {
      number: TEXTS.number01S,
      title: TEXTS.DDTProcessesTitle_1,
      text: TEXTS.DDTProcessesText_1,
      ref: step1
    },
    {
      number: TEXTS.number02S,
      title: TEXTS.DDTProcessesTitle_2,
      text: TEXTS.DDTProcessesText_2,
    },
    {
      number: TEXTS.number03S,
      title: TEXTS.DDTProcessesTitle_3,
      text: TEXTS.DDTProcessesText_3,
    },
    {
      number: TEXTS.number04S,
      title: TEXTS.DDTProcessesTitle_4,
      text: TEXTS.DDTProcessesText_4,
    },
    {
      number: TEXTS.number05S,
      title: TEXTS.DDTProcessesTitle_5,
      text: TEXTS.DDTProcessesText_5,
      ref: step5
    }
  ]

  return (
    <div className="ddt-process" >

      <div >
        <div className='ddt-process-sticky' >
          <div style={{ overflow: 'hidden', position: 'relative', borderRadius: '12px' }} >

            <img
              src={DDTProcessPngImg1}
              alt=""
              className="ddt-process-img"
              style={{
                transform: img001 ? 'translateX(0%)' : 'translateX(0)',
                transition: 'transform 0.5s',
                objectFit: 'cover'
              }} />

            <img
              src={DDTProcessPngImg2}
              alt=""
              className="ddt-process-img"
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: '50%',
                top: '50%',
                transform: img002 ? 'translate(-50%, -50%)' : 'translate(-200%, -50%)',
                transition: 'transform 0.6s',
                objectFit: 'cover'
              }} />

          </div>
        </div>
      </div>


      <div className="ddt-process-inform" >

        <h2 className="ddt-process-inform_title" >{TEXTS.DDTProcessTitle}</h2>

        {processes.map((item, index) => (
          <div className='ddt-process-step' key={index} >
            <div >
              <p className="ddt-process-step_number" >{item.number}</p>
              <h3 className="ddt-process-step_title" ref={item.ref}>{item.title}</h3>
            </div>
            <div>
              <p className="ddt-process-step_text">{item.text}</p>
            </div>
          </div>
        ))}

      </div>
    </div >
  )
}

export default DDTProcess;
