import React, { useEffect } from 'react';
import style from './Preloader.module.css';
import logo from '../../img/logo_preloader.png';



const Preloader = () => {

  useEffect(() => {

    let shutters = document.querySelectorAll(`.${style.shutter}`);
    let progressBars = document.querySelectorAll(`.${style.progressBar}`);
    let body = document.getElementsByTagName('body');

    body[0].classList.add(style.bodyOverflowHidden);

    setTimeout(() => {
      progressBars[0].style.width = '50%';
      progressBars[1].style.width = '50%';
    }, 100)

    setTimeout(() => {
      shutters[0].style.top = '-50%';
      shutters[1].style.top = '50%';
    }, 3000)

    setTimeout(() => {
      body[0].classList.remove(style.bodyOverflowHidden);
    }, 3500)

  }, []);



  return (
    <div className={style.wrapper} id='preloader'>

      <div className={style.shutter}>
        <img src={logo} className={style.logo} alt="nitrix" />
        <p className={style.logo__animation}></p>
      </div>

      <div className={style.shutter}>
        <div className={style.progressBar}></div>
        <div className={style.progressBar}></div>
      </div>

    </div>
  )
}

export default Preloader;