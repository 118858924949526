import './OutstaffText.css';
import { TEXTS } from '../../texts';
import Flashlight from '../Flashlight/Flashlight';
import OutstaffFlashlightBlackWhite from '../../img/service/outstaff/OutstaffFlashlightBlack&White.webp';
import OutstaffFlashlightMobile from '../../img/service/outstaff/OutstaffFlashlightMobile.webp';
import OutstaffFlashlightColor from '../../img/service/outstaff/OutstaffFlashlightColor.webp';
import cursor from '../../img/move_cursor_white.svg';



const OutstaffText = () => {



  return (
    <section>

      <h3 className="outstaff-subtitle">{TEXTS.outstaffSubtitle}</h3>
      <h1 className="outstaff-title">{TEXTS.outstaffTitle}</h1>

      <div className="outstaff-flashlight-wrapper">
        <Flashlight
          imgFront={OutstaffFlashlightBlackWhite}
          imgBack={OutstaffFlashlightColor}
          imgMobile={OutstaffFlashlightMobile}
          imgFrontId='img__outstaff1'
          imgBackId='img__outstaff2'
          loopSize={170}
          cursor={cursor}
          cursorHeight={120}
          cursorWidth={120}
          cursorId='cursor__outstaff'
          objectPosition={'50% 50%'}
          mobileObjectPosition={'50% 50%'}
          newTab={false}
        />
      </div>

    </section>
  )
}

export default OutstaffText;