import FooterJobApplicationForm from "../FooterJobApplicationForm/FooterJobApplicationForm";
import FooterSocial from "../FooterSocial/FooterSocial";
import FooterPrivacy from "../FooterPrivacy/FooterPrivacy";



const FooterCareers = () => {
    
    return (
        <footer>
            <FooterJobApplicationForm />
            <FooterSocial />
            <FooterPrivacy />
        </footer>
    )
}

export default FooterCareers;