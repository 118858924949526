import React from 'react';
import Header from '../components/Header/Header';
import ServicesText from '../components/ServicesText/ServicesText';
import ServicesReason from '../components/ServicesReason/ServicesReason';
import ServicesProvide from '../components/ServicesProvide/ServicesProvide';
import ServicesStack from '../components/ServicesStack/ServicesStack';
import ServicesFaq from '../components/ServicesFaq/ServicesFaq';
import Footer from '../components/Footer/Footer';
import { withCookiesCheker } from '../HOCs/withCookiesCheker';
import { useScrollToId } from '../hooks/useScrollToId';



const Services = () => {

  const scrollToId = useScrollToId();

  return (
    <>
      <Header />
      <ServicesText />
      <ServicesReason />
      <ServicesProvide />
      <ServicesStack />
      <ServicesFaq />
      <Footer />
    </>
  )
}

export default withCookiesCheker(Services);