import React, { useState } from 'react';
import './PortfolioCase.css';
import { TEXTS } from '../../texts';
import CaseCard from './CaseCard/CaseCard';
import cursor from '../../img/portfolio-card-cursor.webp';

import ZiseLogo from '../../img/portfolio/Zise.webp'
import ZiseUnderCover from '../../img/portfolio/ZiseUnderCover.webp'
import AquaPalLogo from '../../img/portfolio/AquaPal.webp'
import AquaPalUnderCover from '../../img/portfolio/AquaPalUnderCover.jpg'
import JoynLogo from '../../img/portfolio/Joyn.webp'
import JoynUnderCover from '../../img/portfolio/JoynUnderCover.jpg'
import IsraelLogo from '../../img/portfolio/Israel.webp'
import IsraelUnderCover from '../../img/portfolio/IsraelUnderCover.webp'
import StartupLogo from '../../img/portfolio/Startup.webp'
import StartupUnderCover from '../../img/portfolio/StartupUnderCover.webp'
import TailorsLogo from '../../img/portfolio/Tailors.webp'
import TailorsUnderCover from '../../img/portfolio/TailorsUnderCover.webp'
import ZiksenLogo from '../../img/portfolio/Ziksen.webp'
import ZiksenUnderCover from '../../img/portfolio/ZiksenUnderCover.webp'
import SaqureLogo from '../../img/portfolio/Saqure.webp'
import SaqureUnderCover from '../../img/portfolio/SaqureUnderCover.webp'
import RainbowLogo from '../../img/portfolio/Rainbow.webp'
import RainbowUnderCover from '../../img/portfolio/RainbowUnderCover.webp'
import HalvesLogo from '../../img/portfolio/Halves.webp'
import HalvesUnderCover from '../../img/portfolio/HalvesUnderCover.webp'


const portfolioCases = [
  {
    year: TEXTS.ZiseYear,
    title: TEXTS.Zise,
    industries: [TEXTS.ANDROID],
    banner: ZiseLogo,
    bannerUnderCover: ZiseUnderCover,
    text: [TEXTS.ZiseDescription],
    caseName: TEXTS.ZiseAppDescription,
    href: '/cases/zise'
  },
  {
    year: TEXTS.AquaPalYear,
    title: TEXTS.AquaPal,
    industries: [TEXTS.FLUTTER, TEXTS.DESIGN, TEXTS.QA],
    banner: AquaPalLogo,
    bannerUnderCover: AquaPalUnderCover,
    text: [TEXTS.AquaPalDescription],
    caseName: TEXTS.AquaPalAppDescription,
    href: '/cases/aqua-pal'
  },
  {
    year: TEXTS.JoynYear,
    title: TEXTS.Joyn,
    industries: [TEXTS.FLUTTER, TEXTS.QA],
    banner: JoynLogo,
    bannerUnderCover: JoynUnderCover,
    text: [TEXTS.JoynDescription],
    caseName: TEXTS.JoynAppDescription,
    href: '/cases/joyn'
  },
  {
    year: TEXTS.IsraelTVYear,
    title: TEXTS.IsraelTV,
    industries: [TEXTS.ANDROID, TEXTS.iOS, TEXTS.QA],
    banner: IsraelLogo,
    bannerUnderCover: IsraelUnderCover,
    text: [TEXTS.IsraelTVDescription],
    caseName: TEXTS.IsraelTVAppDescription,
    href: '/cases/israel-tv'
  },
  {
    year: TEXTS.StartupShowYear,
    title: TEXTS.StartupShow,
    industries: [TEXTS.iOS, TEXTS.ANDROID, TEXTS.QA],
    banner: StartupLogo,
    bannerUnderCover: StartupUnderCover,
    text: TEXTS.StartupShowDescription,
    caseName: TEXTS.StartupShowAppDescription,
    href: '/cases/startup-show'
  },
  {
    year: TEXTS.TailorsYear,
    title: TEXTS.Tailors,
    industries: [TEXTS.FLUTTER, TEXTS.DESIGN, TEXTS.QA],
    banner: TailorsLogo,
    bannerUnderCover: TailorsUnderCover,
    text: [TEXTS.TailorsDescription],
    caseName: TEXTS.TailorsAppDescription,
    href: '/cases/tailors'
  },
  {
    year: TEXTS.ZiksenYear,
    title: TEXTS.Ziksen,
    industries: [TEXTS.ANDROID, TEXTS.QA],
    banner: ZiksenLogo,
    bannerUnderCover: ZiksenUnderCover,
    text: [TEXTS.ZiksenDescription],
    caseName: TEXTS.ZiksenAppDescription,
    href: '/cases/ziksen'
  },

  {
    year: TEXTS.SaQureYear,
    title: TEXTS.SaQure,
    industries: [TEXTS.iOS, TEXTS.ANDROID, TEXTS.QA],
    banner: SaqureLogo,
    bannerUnderCover: SaqureUnderCover,
    text: [TEXTS.SaQureDescription],
    caseName: 'Massage App',
    href: '/cases/saqure'
  },
  {
    year: TEXTS.RainbowYear,
    title: TEXTS.Rainbow,
    industries: [TEXTS.WEB, TEXTS.iOS, TEXTS.ANDROID],
    banner: RainbowLogo,
    bannerUnderCover: RainbowUnderCover,
    text: [TEXTS.RainbowDescription],
    caseName: TEXTS.RainbowAppDescription,
    href: '/cases/rainbow'
  },
  {
    year: TEXTS.HalvesYear,
    title: TEXTS.Halves,
    industries: [TEXTS.WEB, TEXTS.iOS, TEXTS.DESIGN],
    banner: HalvesLogo,
    bannerUnderCover: HalvesUnderCover,
    text: [TEXTS.HalvesDescription],
    caseName: TEXTS.HalvesAppDescription,
    href: '/cases/halves'
  }

]



const PortfolioCase = () => {

  const [resX, setResX] = useState();
  const [resY, setResY] = useState();

  const loopAnimation = (e) => {

    const cursor = document.querySelector('.portfolio-case-cursor');
    const div = document.querySelector('.portfolio-case-preview');

    let x = e.pageX;
    let y = e.pageY;

    let percW = div.offsetWidth / 100;
    let percH = div.offsetHeight / 100;
    let resW = x - div.offsetLeft;
    let resH = y - div.offsetTop;

    setResX(resW / percW);
    setResY(resH / percH);

    cursor.style.top = `${resY}%`;
    cursor.style.left = `${resX}%`;
    cursor.style.width = `150px`;
    cursor.style.height = `150px`;
    cursor.classList.add('active');
  }

  const animationStart = () => {

    const cursor = document.querySelector('.portfolio-case-cursor');
    cursor.style.transition = 'ease .0s';
    cursor.classList.add('portfolio-case-cursor-start');

    setTimeout(() => {
      cursor.classList.remove('portfolio-case-cursor-start');
    }, 500);
  }

  const animationEnd = () => {
    const cursor = document.querySelector('.portfolio-case-cursor');
    cursor.style.transition = 'ease .2s';
    cursor.style.width = `0px`;
    cursor.style.height = `0px`;
  }

  return (
    <div className="portfolio-case" >

      <div className="portfolio-case-desc">
        <h3 className="portfolio-case-desc-title">{TEXTS.ourCases}</h3>
        <p className="portfolio-case-desc-text">{TEXTS.NitrixSupportsStartupsAndCompanies}</p>

        <a href="#contact" className="portfolio-case-desc-button">Start a<br />project</a>
      </div>

      <div className="portfolio-case-preview"
        onMouseMove={e => loopAnimation(e)}
        onMouseEnter={animationStart}
        onMouseLeave={animationEnd}
      >

        <img src={cursor} alt="" className='portfolio-case-cursor' />

        {portfolioCases.map((portfolioCase, index) => (

          <div onClick={() => window.location.href = portfolioCase.href} key={index}>
            <CaseCard portfolioCase={portfolioCase} />
          </div>

        ))}

      </div>

    </div>
  )
}

export default PortfolioCase;