import React, { useState } from 'react';



const PortfolioCard = ({ portfolioCase }) => {

    const [changeImage, setChangeImage] = useState(false);

    return (
        <div ref={portfolioCase.ref} style={{ borderRadius: '12px' }}>

            <div style={{ overflow: 'hidden', position: 'relative', borderRadius: '12px' }} className="portfolio-img" onMouseLeave={() => setChangeImage(false)}>

                <img
                    src={portfolioCase.banner}
                    alt=""
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        transform: changeImage ? 'translateX(0%)' : 'translateX(0)',
                        transition: 'transform 0.5s',
                        borderRadius: '12px'
                    }}
                    onMouseEnter={() => setChangeImage(true)}
                />

                <img
                    src={portfolioCase.bannerUnderCover}
                    alt=""
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        left: '50%',
                        top: '50%',
                        transform: changeImage ? 'translate(-50%, -50%)' : 'translate(-200%, -50%)',
                        transition: 'transform 0.6s',
                        objectFit: 'cover',
                        borderRadius: '12px'
                    }}
                />

            </div>
        </div>
    )
}

export default PortfolioCard;