import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getAllVacancies } from "../store/slices/vacanciesSlice";


export const useCheckActiveVacancies = () => {

    const dispatch = useDispatch();
    const [activeVacancies, setActiveVacancies] = useState([]);

    useEffect(() => {
        
        const fetchData = async () => {
            const res = await dispatch(getAllVacancies());
            checkActiveVacancies(res.payload);
        };
        fetchData();

    }, []);

    const checkActiveVacancies = (vacancies) => {
        const activeVacancies = vacancies.filter((vacancy) => vacancy.visibility);
        setActiveVacancies(activeVacancies);
    };

    return activeVacancies;
}



